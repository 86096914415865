import React from 'react';
import { Field, useField } from 'formik';
import { HelpText } from './Field';

export interface Option {
    label: string;
    value: string;
}

export interface CheckboxListFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
    label: string;
    inputStyle?: React.CSSProperties;
    col?: string;
    required?: boolean;
    helpText?: string;
    options: Option[];
}

export const CheckboxListField = ({ label, options, inputStyle, col, required, helpText, ...props}: CheckboxListFieldProps) => {
    //const [field, meta] = useField(props.name!);

    //<input type="checkbox" style={inputStyle} {...field} {...props} value={option.value} />
    const inputs = options.map((option) => (
        <div className="control">
        <label className="checkbox">
            <Field type="checkbox" name={props.name!} value={option.value} />
            {option.label}
        </label>
        </div>
        ));

    return (
        <div className="field">
            <label id={props.id ?? props.name} className={required ? "label required" : "label"}>
                {label}
                <HelpText content={helpText} />
            </label>
            <div role="group" aria-labelledby={props.id ?? props.name}>
                {col ? (
                    <div className="columns is-gapless mb-0">
                        <div className={`column ${col}`}>
                            {inputs}
                        </div>
                    </div>
                ) : inputs}
                {/*meta.touched && meta.error ? (
                    <p className="help is-danger">{meta.error}</p>
                ) : null*/}
            </div>
        </div>
    );
}