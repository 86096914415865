import { useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { gql, useQuery, useMutation } from '@apollo/client';
import * as yup from 'yup';
import { DollarDisplayField, DollarField } from '../../components/ui';
import { encodeId } from '../../util';
import { toast } from 'react-toastify';
import { MAKE_LOAN_PAYMENT } from './RegularPaymentForm';
import { MakeLoanPayment } from './__generated__/MakeLoanPayment';
import { GetLoanBalance } from './__generated__/GetLoanBalance';

const GET_LOAN_BALANCE = gql`
    query GetLoanBalance($loanId: ID!) {
        loan(id: $loanId) {
            loan {
                balance
            }
        }
    }`;

const schema = yup.object().shape({
    amount: yup.string()
        .test(
            "formatted-number",
            "Amount must be a number.",
            value => /^(\d{1,}|(\d{1,3}(,\d{3})+))(\.\d{2})?$/.test(value ?? "")
        )
        .required("Amount is required.")
        .test(
            "min-value",
            "Amount must be greater than 0.",
            value => Number(value?.replace(/,/g, '')) >= 0
        )
});

export const PrincipalPaymentForm = ({ loanId }: { loanId: string }) => {
    const navigate = useNavigate();
    const [makePayment, mutationState] = useMutation<MakeLoanPayment>(MAKE_LOAN_PAYMENT);

    const { data, loading, error } = useQuery<GetLoanBalance>(GET_LOAN_BALANCE, {
        variables: { 
            loanId: encodeId('Loan', loanId!)
        }
    });

    if (loading) {
        return <div>Spinner</div>;
    }

    if (error) {
        console.error(error);
        return <div>Sorry, an error occured while loading this loan.</div>;
    }

    const loan = data!.loan!.loan!;

    return (
        <>
            <div className="columns">
                <div className="column is-4">
                    <DollarDisplayField label="Balance Remaining" value={loan.balance} />
                </div>
            </div>
            <Formik
                initialValues={{ amount: '' }}
                validationSchema={schema}
                onSubmit={(values) => {
                    makePayment({
                        variables: {
                            payment: {
                                loanId: Number(loanId),
                                amount: values.amount,
                                isPrincipalPayment: true
                            }
                        },
                        onCompleted: (result) => {
                            if (result.makePayment?.__typename === "LoanPaymentSuccess") {
                                toast.success(`Payment for $${values.amount} made.`)
                                navigate(`/loans/${loanId}`);
                            }
                        }
                    });
                }}>
                <Form>
                    <div className="columns">
                        <div className="column is-3">
                            <DollarField label="Amount" placeholder="500.00" name="amount" required />
                        </div>
                    </div>
                    <button className="button is-primary" type="submit" disabled={loading || mutationState.loading}>Make Payment</button>
                </Form>
            </Formik>
        </>
    )
}