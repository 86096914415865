import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { addDays, format, isAfter, isBefore, parseISO } from 'date-fns';
import { DisplayField, DollarDisplayField } from '../../components';
import { encodeId, interestDueForPeriod } from '../../util';
import { GetPayoffStatementInfo } from './__generated__/GetPayoffStatementInfo';
import { nextPeriod } from '../../util';

const GET_PAYOFF_DATA = gql`
    query GetPayoffStatementInfo($loanId: ID!) {
        loan(id: $loanId) {
            loan {
                id
                loanId
                principal
                amortization
                period
                interestRate
                firstPaymentDate
                fee
                paymentAmount
                balance
                dueDate
                duePrincipal
                dueInterest
                duePenalties
                dueFees
            }
        }
    }`;

export const PayoffStatement = () => {
    const params = useParams();
    const { loanId } = params;
    const [ payoffDate, setPayoffDate ] = useState<Date>();
    const { data, loading, error } = useQuery<GetPayoffStatementInfo>(GET_PAYOFF_DATA, {
        variables: { 
            loanId: encodeId('Loan', loanId!)
        }
    });

    const payoffInfo = useMemo(() => {
        if (!payoffDate || !data) {
            return null;
        }
        
        const loan = data.loan!.loan!;

        if (isBefore(payoffDate, loan.firstPaymentDate)) {
            return null;
        }

        const now = new Date();
        let date = parseISO(loan.firstPaymentDate);
        let remainingPrincipal = loan.balance;
        while (isBefore(date, payoffDate)) {
            // assume all future payments are made on time
            if (isAfter(date, now)) {
                const interestDue = interestDueForPeriod(remainingPrincipal, loan.interestRate, loan.period);
                const payment = Math.min(interestDue + remainingPrincipal + loan.fee, loan.paymentAmount);
                const principalDue = payment - interestDue - loan.fee;
                remainingPrincipal -= principalDue;
            }
            
            date = nextPeriod(date, loan.period); // addMonths(date, 1);
        }
        
        // TODO: Need to look at the contract again to see when the due date is updated. Maybe we 
        // need to ensure a contract is up to date before viewing it or producing this payoff statement?
        
        //Expiration: The day before the next payment date after the selected date.
        const expiration = addDays(date, -1);
        
        // The payoff balance amount is the same as the payoff amount formula, but assumes all future 
        // payments between the current time and the selected payoff date will be made on time.
        let payoffBalance = remainingPrincipal + loan.duePenalties + loan.dueFees;
        
        // If interest and principal are overdue, they should be included in the payoff balance
        const currentDueDate = parseISO(loan.dueDate);
        if (isBefore(currentDueDate, new Date())) {
            payoffBalance +=  (loan.dueInterest + loan.duePrincipal + loan.dueFees);
        }

        // Daily interest: Again, provided all payments are made on time, the daily interest rate shows the amount of 
        // interest accruing per day between the last scheduled payment and the payoff date.
        const dailyInterestRate = loan.interestRate / 365;
        const dailyInterest = remainingPrincipal * dailyInterestRate;

        return {
            payoffBalance,
            dailyInterest,
            expiration,
        };
    }, [payoffDate, data]);

    if (loading) {
        return <div>Spinner</div>;
    }

    if (error) {
        return <div>Sorry, there was an error: {error}</div>;
    }

    return (
        <>
            <h2 className="title is-2">Payoff Statement</h2>
            <div className="columns">
                <div className="column is-4">
                    <div className="field">
                        <label className="label">Date</label>
                        <div className="control">
                            <input type="date" className="input" onChange={e => setPayoffDate(new Date(e.target.value))} />
                        </div>
                    </div>
                </div>
            </div>
            {payoffInfo ? (
                <div className="columns">
                    <div className="column is-4">
                        <DollarDisplayField label="Payoff Balance" value={payoffInfo.payoffBalance} />
                    </div>
                    <div className="column is-4">
                        <DollarDisplayField label="Daily Interest" value={payoffInfo.dailyInterest} />
                    </div>
                    <div className="column is-4">
                        <DisplayField label="Expiration" value={format(payoffInfo.expiration, 'P')} />
                    </div>
                </div>
            ) : null}
        </>
    );
}
