import React from 'react';
import { useField } from 'formik';
import { formatNumber, formatPhone } from '../../util';

export interface CommonFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
    label: string;
    inputStyle?: React.CSSProperties;
    col?: string;
    format?: (val: string) => string;
    required?: boolean;
    helpText?: string;
}

export interface FieldProps extends CommonFieldProps {
    prefixContent?: string | undefined;
    postfixContent?: string | undefined;
}

export interface FieldWithIconProps extends CommonFieldProps {
    icon?: string | undefined;
    rightIcon?: string | undefined;
}

export const HelpText = ({content}: { content: string | undefined}) => {
    if (!content || content.length === 0) {
        return null;
    }
    return (
        <span className="icon has-tooltip-arrow" data-tooltip={content}>
            <i className="far fa-question-circle"></i>
        </span>
    );
}

export const Field = ({ label, prefixContent, postfixContent, inputStyle, col, format, required, helpText, ...props}: FieldProps) => {
    const [field, meta, helpers] = useField(props.name!);
    const preChildren = <StaticFieldAddOn>{prefixContent}</StaticFieldAddOn>;
    const postChildren = <StaticFieldAddOn>{postfixContent}</StaticFieldAddOn>;
    const fieldClass = prefixContent || postfixContent ? "field has-addons" : "field";

    const handleBlur = format ?
        (e: React.FocusEvent<HTMLInputElement>) => {
            helpers.setValue(format(e.target.value));
        } : field.onBlur;

    const inputClass = meta.error && meta.touched ? 
        "input is-danger" :
        "input";

    const input = (
        <div className={fieldClass}>
            {preChildren}
            <div className="control">
                <input className={inputClass} style={inputStyle} aria-required={required ? true : false} {...field} {...props} onBlur={handleBlur} />
            </div>
            {postChildren}
        </div>);

    return (
        <div className="field">
            <label htmlFor={props.id || props.name} className={required ? "label required" : "label"}>
                {label}
                <HelpText content={helpText} />
            </label>
            {col ? (
                <div className="columns is-gapless mb-0">
                    <div className={`column ${col}`}>
                        {input}
                    </div>
                </div>
            ) : input}
            {meta.touched && meta.error ? (
                <p className="help is-danger">{meta.error}</p>
            ) : null}
        </div>
    );
}

export const FieldWithIcon = ({ label, icon, rightIcon, inputStyle, col, format, required, helpText, ...props}: FieldWithIconProps) => {
    const [field, meta, helpers] = useField(props.name!);
    const css: string[] = ["control"];
    if (icon) {
        css.push("has-icons-left");
    }
    if (rightIcon) {
        css.push("has-icons-right");
    }

    const handleBlur = format ?
        (e: React.FocusEvent<HTMLInputElement>) => {
            helpers.setValue(format(e.target.value));
        } : field.onBlur;

    const inputClass = meta.error && meta.touched ? 
        "input is-danger" :
        "input";

    const input = (
        <div className={css.join(' ')}>
            <input className={inputClass} style={inputStyle} aria-required={required ? true : false} 
                {...field} {...props} 
                onBlur={handleBlur} />
            {icon ? (
                <span className="icon is-left">
                    <i className={icon}></i>
                </span>
            ) : null}
            {rightIcon ? (
                <span className="icon is-right">
                    <i className={rightIcon}></i>
                </span>
            ) : null}
        </div>
    );

    return (
        <div className="field">
            <label htmlFor={props.id || props.name} 
                className={required ? "label required" : "label"}>
                {label}
                <HelpText content={helpText} />
            </label>
            {col ? (
                <div className="columns is-gapless mb-0">
                    <div className={`column ${col}`}>
                        {input}
                    </div>
                </div>
            ) : input}
            {meta.touched && meta.error ? (
                <p className="help is-danger">{meta.error}</p>
            ) : null}
        </div>
    );
}

export const StaticFieldAddOn = (props: React.PropsWithChildren<{}>) => {
    if (!props.children)  {
        return null;
    }
    return (
        <p className="control">
            <a className="button is-static">
                {props.children}
            </a>
        </p>
    );
}

export const PercentField = (props: FieldProps) => {
    const style: React.CSSProperties = {
        textAlign: 'right',
    }
    return (
        <FieldWithIcon style={style} maxLength={5} rightIcon="fas fa-percent" type="text"
            format={formatNumber}  
            {...props} />
    )
}

export const DollarField = (props: FieldProps) => {
    return (
        <FieldWithIcon icon="fas fa-dollar-sign" type="text" maxLength={11} format={formatNumber} {...props} />
    )
}

export const PhoneField = (props: FieldProps) => {
    return (
        <Field type="text" format={formatPhone} {...props} />
    )
}

export interface SelectFieldProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
    label: string;
    required?: boolean;
}

export const StateField = ({ label, required, ...props}: SelectFieldProps) => {
    const [field, meta] = useField(props.name!);
    const containerClass = meta.error && meta.touched ? 
        "control select is-danger" :
        "control select";
    return (
        <div className="field">
            <label htmlFor={props.id || props.name} className={required ? "label required" : "label"}>{label}</label>
            <div className={containerClass}>
                <select {...field} {...props}>
                    <option>-Select One-</option>
                    <option value="AL">Alabama</option>
                    <option value="AK">Alaska</option>
                    <option value="AZ">Arizona</option>
                    <option value="AR">Arkansas</option>
                    <option value="CA">California</option>
                    <option value="CO">Colorado</option>
                    <option value="CT">Connecticut</option>
                    <option value="DE">Delaware</option>
                    <option value="DC">District Of Columbia</option>
                    <option value="FL">Florida</option>
                    <option value="GA">Georgia</option>
                    <option value="HI">Hawaii</option>
                    <option value="ID">Idaho</option>
                    <option value="IL">Illinois</option>
                    <option value="IN">Indiana</option>
                    <option value="IA">Iowa</option>
                    <option value="KS">Kansas</option>
                    <option value="KY">Kentucky</option>
                    <option value="LA">Louisiana</option>
                    <option value="ME">Maine</option>
                    <option value="MD">Maryland</option>
                    <option value="MA">Massachusetts</option>
                    <option value="MI">Michigan</option>
                    <option value="MN">Minnesota</option>
                    <option value="MS">Mississippi</option>
                    <option value="MO">Missouri</option>
                    <option value="MT">Montana</option>
                    <option value="NE">Nebraska</option>
                    <option value="NV">Nevada</option>
                    <option value="NH">New Hampshire</option>
                    <option value="NJ">New Jersey</option>
                    <option value="NM">New Mexico</option>
                    <option value="NY">New York</option>
                    <option value="NC">North Carolina</option>
                    <option value="ND">North Dakota</option>
                    <option value="OH">Ohio</option>
                    <option value="OK">Oklahoma</option>
                    <option value="OR">Oregon</option>
                    <option value="PA">Pennsylvania</option>
                    <option value="RI">Rhode Island</option>
                    <option value="SC">South Carolina</option>
                    <option value="SD">South Dakota</option>
                    <option value="TN">Tennessee</option>
                    <option value="TX">Texas</option>
                    <option value="UT">Utah</option>
                    <option value="VT">Vermont</option>
                    <option value="VA">Virginia</option>
                    <option value="WA">Washington</option>
                    <option value="WV">West Virginia</option>
                    <option value="WI">Wisconsin</option>
                    <option value="WY">Wyoming</option>
                </select>
            </div>
            {meta.touched && meta.error ? (
                <p className="help is-danger">{meta.error}</p>
            ) : null}
        </div>
    )
}

export const PeriodField = ({ label, required, ...props}: SelectFieldProps) => {
    const [field, meta] = useField(props.name!);
    const containerClass = meta.error && meta.touched ? 
        "control select is-danger" :
        "control select";
    return (
        <div className="field">
            <label htmlFor={props.id || props.name} className={required ? "label required" : "label"}>
                {label}
                <HelpText content="How long is each period of the loan?" />
            </label>
            <div className={containerClass}>
                <select {...field} {...props}>
                    <option value="2628000">Monthly</option>
                    <option value="604800">Weekly</option>
                    <option value="86400">Daily</option>
                    <option value="3600">Hourly</option>
                </select>
            </div>
            {meta.touched && meta.error ? (
                <p className="help is-danger">{meta.error}</p>
            ) : null}
        </div>
    )
}

export const Checkbox = ({ label, prefixContent, postfixContent, inputStyle, col, format, required, helpText, ...props}: FieldProps) => {
    const [field, meta, helpers] = useField(props.name!);
    const preChildren = <StaticFieldAddOn>{prefixContent}</StaticFieldAddOn>;
    const postChildren = <StaticFieldAddOn>{postfixContent}</StaticFieldAddOn>;
    const fieldClass = prefixContent || postfixContent ? "field has-addons" : "field";

    const handleBlur = format ?
        (e: React.FocusEvent<HTMLInputElement>) => {
            helpers.setValue(format(e.target.value));
        } : field.onBlur;

    const inputClass = meta.error && meta.touched ? 
        "is-danger" :
        "";

    const input = (
        <div className={fieldClass}>
            {preChildren}
            <div className="control">
                <input className={inputClass} style={inputStyle} aria-required={required ? true : false} {...field} {...props} onBlur={handleBlur} type="checkbox" />
            </div>
            {postChildren}
        </div>);

    return (
        <div className="field">
            <label htmlFor={props.id || props.name} className={required ? "label required" : "label"}>
                {label}
                <HelpText content={helpText} />
            </label>
            {col ? (
                <div className="columns is-gapless mb-0">
                    <div className={`column ${col}`}>
                        {input}
                    </div>
                </div>
            ) : input}
            {meta.touched && meta.error ? (
                <p className="help is-danger">{meta.error}</p>
            ) : null}
        </div>
    );
}