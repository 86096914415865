import React, { createContext, useContext, useEffect, useState } from 'react';
import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { Auth } from 'aws-amplify';
import { ISignUpResult } from 'amazon-cognito-identity-js';
import { Me } from './__generated__/Me';
import { Role } from '../types';

interface ChildProps {
    children: React.ReactElement | React.ReactElement[]
};

export enum AuthStatus {
    Loading,
    SignedIn,
    SignedOut
};

export interface SessionInfo {
    username?: string
    email?: string
    sub?: string
    accessToken?: string
    refreshToken?: string
    firstName?: string
    lastName?: string
    roles?: Role[]
}

export interface IAuth {
    sessionInfo?: SessionInfo | null
    authStatus?: AuthStatus
    confirmSignUp: (username: string, code: string) => void
    signIn: (username: string, password: string) => void
    signUp: (username: string, password: string, attributes?: object) => Promise<ISignUpResult>
    signOut: () => void
    resendCode: (username: string) => void
    forgotPassword: (username: string) => void
    forgotPasswordSubmit: (username: string, code: string, password: string) => void
    changePassword?: any
};

export interface UserRegistrationArguments {
    username: string
    password: string
    attributes?: object
};

const ME_QUERY = gql`
    query Me {
        me {
            roles
        }
    }
`;

const signUp = async (username: string, password: string, attributes?: object) => {
    const result = await Auth.signUp({
        username,
        password,
        attributes
    });
    return result;
}

const confirmSignUp = async (username: string, code: string) => {
    await Auth.confirmSignUp(username, code);
}

const signIn = async (username: string, password: string) => {
    await Auth.signIn(username, password);
}

const signOut = async () => {
    await Auth.signOut();
}

const resendCode = async (username: string) => {
    await Auth.resendSignUp(username);
}

const forgotPassword = async (username: string) => {
    await Auth.forgotPassword(username);
}

const forgotPasswordSubmit = async (username: string, code: string, password: string) => {
    await Auth.forgotPasswordSubmit(username, code, password);
}

export const AuthContext = createContext<IAuth>({
    sessionInfo: {},
    authStatus: AuthStatus.Loading,
    signUp,
    confirmSignUp,
    signIn,
    signOut,
    resendCode,
    forgotPassword,
    forgotPasswordSubmit
});

export const AuthIsSignedIn = ({children}: ChildProps) => {
    const { authStatus } = useContext(AuthContext);
    return (
        <>
            {authStatus === AuthStatus.SignedIn ? children : null}
        </>
    );
}

export const AuthIsSignedOut = ({children}: ChildProps) => {
    const { authStatus } = useContext(AuthContext);
    return (
        <>
            {authStatus === AuthStatus.SignedOut ? children : null}
        </>
    );
}

export const AuthIsAdmin = ({children}: ChildProps) => {
    const { authStatus, sessionInfo } = useContext(AuthContext);
    return (
        <>
            {authStatus === AuthStatus.SignedIn && sessionInfo?.roles?.includes(Role.Admin) ? children : null}
        </>
    );
}

export const AuthIsLender = ({children}: ChildProps) => {
    const { authStatus, sessionInfo } = useContext(AuthContext);
    return (
        <>
            {authStatus === AuthStatus.SignedIn && sessionInfo?.roles?.includes(Role.Lender) ? children : null}
        </>
    );
}

export const AuthIsBorrower = ({children}: ChildProps) => {
    const { authStatus, sessionInfo } = useContext(AuthContext);
    return (
        <>
            {authStatus === AuthStatus.SignedIn && sessionInfo?.roles?.includes(Role.Borrower) ? children : null}
        </>
    );
}

export const AuthProvider = ({children}: ChildProps) => {
    const [authStatus, setAuthStatus] = useState(AuthStatus.Loading);
    const [sessionInfo, setSessionInfo] = useState<SessionInfo | null>(null);
    const { refetch } = useQuery<Me>(ME_QUERY, { 
        skip: (authStatus !== AuthStatus.SignedIn || (sessionInfo?.roles != null && sessionInfo.roles.length > 0))
    });

    useEffect(() => {
        const getSessionInfo = async () => {
            try {
                const session = await Auth.currentSession();
                if (session && session.isValid()) {
                    const user = await Auth.currentAuthenticatedUser();
                    const profileQueryResponse = await refetch();
                    setSessionInfo({
                        accessToken: session.getAccessToken().getJwtToken(),
                        refreshToken: session.getRefreshToken().getToken(),
                        username: user.attributes.email,
                        email: user.attributes.email,
                        sub: user.attributes.sub,
                        firstName: user.attributes.given_name,
                        lastName: user.attributes.family_name,
                        roles: profileQueryResponse.data.me.roles,
                    });
                    setAuthStatus(AuthStatus.SignedIn);
                }
            } catch (e) {
                console.log('error: ', e);
                setAuthStatus(AuthStatus.SignedOut);
            }
        }
        getSessionInfo();
    }, [setAuthStatus, authStatus, refetch]);

    if (authStatus === AuthStatus.Loading) {
        return null;
    }

    const state: IAuth = {
        authStatus,
        sessionInfo,
        signUp,
        confirmSignUp,
        resendCode,
        forgotPassword,
        forgotPasswordSubmit,
        signIn: async (username: string, password: string) => {
            try {
                await signIn(username, password);
                setAuthStatus(AuthStatus.SignedIn);
            } catch (e) {
                setAuthStatus(AuthStatus.SignedOut);
                setSessionInfo(null);
                throw e;
            }
        },
        signOut: async () => {
            await signOut();
            setAuthStatus(AuthStatus.SignedOut);
            setSessionInfo(null);
        },
    }

    return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>
}

export const useRoles = () => {
    const { authStatus, sessionInfo } = useContext(AuthContext);
    const isInRole = (role: Role) => authStatus === AuthStatus.SignedIn && sessionInfo?.roles?.includes(role);
    const isAdmin = isInRole(Role.Admin);
    const isBorrower = isInRole(Role.Borrower);
    const isLender = isInRole(Role.Lender);
    return {
        isInRole,
        isAdmin,
        isBorrower,
        isLender,
    }
};