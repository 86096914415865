import { format, parseISO } from 'date-fns';
import { interestDueForPeriod, formatCurrency, nextPeriod } from '../../util';

interface FuturePaymentsProps {
    balance: number;
    dueDate: string;
    interestRate: number;
    paymentAmount: number;
    period: number;
    fee: number;
    escrow: number;
}

export const FuturePayments = ({ balance, dueDate, interestRate, paymentAmount, period, fee, escrow }: FuturePaymentsProps) => {
    let remainingPrincipal = balance;
    let nextDueDate = parseISO(dueDate);
    const futurePayments = [];
    while (remainingPrincipal > 0) {
        const interestDue = interestDueForPeriod(remainingPrincipal, interestRate, period);
        const principalDue = Math.min(paymentAmount - interestDue - fee - escrow, remainingPrincipal);
        const payment = Math.min(interestDue + principalDue + fee + escrow, paymentAmount);
        remainingPrincipal -= principalDue;
        futurePayments.push(<tr key={nextDueDate.getTime()}>
            <td>{format(nextDueDate, 'P')}</td>
            <td className="numeric">{formatCurrency(payment)}</td>
            <td className="numeric">{formatCurrency(interestDue)}</td>
            <td className="numeric">{formatCurrency(escrow)}</td>
            <td className="numeric">{formatCurrency(fee)}</td>
            <td className="numeric">{formatCurrency(principalDue)}</td>
            <td className="numeric">{formatCurrency(remainingPrincipal)}</td>
        </tr>);

        nextDueDate = nextPeriod(nextDueDate, period);
    }

    if (futurePayments.length === 0) {
        return <p className="mb-4 is-italic">No future payments required.</p>
    }

    return (
        <table className="table is-striped is-hoverable is-fullwidth">
            <thead>
                <tr>
                    <th>Due Date</th>
                    <th className="numeric">Amount</th>
                    <th className="numeric">Interest</th>
                    <th className="numeric">Escrow</th>
                    <th className="numeric">Fees</th>
                    <th className="numeric">Principal</th>
                    <th className="numeric">Princial Remaining</th>
                </tr>
            </thead>
            <tbody>
                {futurePayments}
            </tbody>
        </table>
    );
}