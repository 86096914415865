import { gql, useQuery } from "@apollo/client"
import { displayError, formatCurrency } from "../../util";
import { GetTokenDashboard } from "./__generated__/GetTokenDashboard";
import { Link } from "react-router-dom";

const GET_TOKEN_DASHBOARD = gql`
    query GetTokenDashboard {
        users {
            ... on UsersQuerySuccess {
                users {
                    fullName
                    balance
                    address
                }
            }
            ... on UsersQueryError {
                title
                message
            }
        }
        token {
            name
            symbol
            totalSupply
        }
    }
`;

export const TokenDashboard = () => {
    const { data, loading } = useQuery<GetTokenDashboard>(GET_TOKEN_DASHBOARD);
    if (loading) {
        return (
            <div className="columns">
                <div className="column">
                    <h2 className="title is-2">Token</h2>
                    [spinner]
                </div>
            </div>
        );
    }

    if (data?.users?.__typename === 'UsersQueryError') {
        return displayError(data.users);
    }

    const users = data!.users!.users!;
    const token = data!.token!;

    return (
        <div className="columns">
            <div className="column">
                <h2 className="title is-2">{token.name} ({token.symbol})</h2>
                <table className="table is-striped is-hoverable is-fullwidth">
                    <thead>
                        <tr>
                            <th></th>
                            <th>User</th>
                            <th>Balance</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map((user) => {
                            return (
                                <tr key={user.address}>
                                    <td className="actions">
                                        <Link to={`/token/${user.address}/edit`}><i className="fas fa-pencil-alt"></i></Link>
                                    </td>
                                    <td>
                                        {user.fullName}
                                    </td>
                                    <td>
                                        {formatCurrency(user.balance)}
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                    <tfoot>
                        <tr>
                            <th colSpan={2}><strong>Total Supply</strong></th>
                            <th>{formatCurrency(token.totalSupply)}</th>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    )
}