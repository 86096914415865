import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { RegularPaymentForm, PrincipalPaymentForm } from '../../components/loan';

enum Tabs { Regular, Principal };

export const MakePayment = () => {
    const params = useParams();
    const { loanId } = params;
    const [activeTab, setActiveTab] = useState<Tabs>(Tabs.Regular);

    return (
        <div className="columns">
            <div className="column">
                <h2 className="title is-2">Make a Payment</h2>
                <div className="tabs">
                    <ul>
                        <li className={activeTab === Tabs.Regular ? "is-active" : ""}><a onClick={() => setActiveTab(Tabs.Regular)}>Regular Payment</a></li>
                        <li className={activeTab === Tabs.Principal ? "is-active" : ""}><a onClick={() => setActiveTab(Tabs.Principal)}>Principal</a></li>
                    </ul>
                </div>
                <div className={activeTab === Tabs.Regular ? "" : "is-hidden"}>
                    <RegularPaymentForm loanId={loanId!} />
                </div>
                <div className={activeTab === Tabs.Principal ? "" : "is-hidden"}>
                    <PrincipalPaymentForm loanId={loanId!} />
                </div>
            </div>
        </div>
    )
}

/*
Matt's notes on below:
- Since the money moves when the form is submitted (and values in the past are not allowed), do we 
  need the date field? Shouldn't it  always be the current date?
- I think we decided to always apply extra funds to the principal, so the third field should not
  be needed anymore, right?
- Should we show some loan summary info at the top (borrower, property, maybe balance or interest rate)?

Manual Make Payment
The payment form should allow the admin to specify these values:
- Payment date (the date the amount will be applied to the loan… cannot be a date in the past)
- Amount
- If amount is greater than the current/next amount due, a third field is visible:
    - Apply extra towards (options: principal or future interest)

Upon submitting the payment form, the amount in stablecoin is moved from the admin wallet and applied to the loan.
*/