import { formatCurrency, formatPercentage } from '../../util';
import { LoanDetails_loan_loan_participants as LoanParticipant } from '../../routes/loans/__generated__/LoanDetails';
import { User } from '../user';

export const Participation = ({ participants }: { participants: LoanParticipant[]}) => {
    if (participants.length === 0) {
        return <p className="mb-4 is-italic">No participants found.</p>
    }

    console.log('participants', participants);
    return (
        <table className="table is-striped is-hoverable">
            <thead>
                <tr>
                    <th>Participant</th>
                    <th className="numeric">Interest Rate</th>
                    <th className="numeric">Dollar Amount</th>
                    <th className="numeric">Effective Ownership</th>
                </tr>
            </thead>
            <tbody>
                {participants.map((participant, i) => {
                    return (
                        <tr key={i}>
                            <td><User user={participant} /></td>
                            <td className="numeric">{formatPercentage(participant.interestRate)}</td>
                            <td className="numeric">{formatCurrency(participant.dollarAmount)}</td>
                            <td className="numeric">{formatPercentage(participant.effectiveOwnership)}</td>
                        </tr>
                    )
                })}
            </tbody>
            <tfoot>
                <tr>
                    <th>Total</th>
                    <th></th>
                    <th></th>
                    <th className="numeric">{formatPercentage(participants.reduce((prev, current) => current.effectiveOwnership + prev, 0))}</th>
                </tr>
            </tfoot>
        </table>
    )
};
