import { useState } from 'react';
import { format, parseISO } from 'date-fns';
import { formatCurrency } from '../../util';
import { LoanDetails_loan_loan_payments } from '../../routes/loans/__generated__/LoanDetails';
import { PaymentDetails } from './PaymentDetails';

interface PaymentHistoryProps {
    principal: number;
    payments: LoanDetails_loan_loan_payments[]
}

export const PaymentHistory = ({ principal, payments }: PaymentHistoryProps) => {
    const [selectedPaymentId, setSelectedPaymentId] = useState<string | null>();
    let paidPrincipal = 0;

    if (payments.length === 0) {
        return <p className="mb-4 is-italic">No payments found.</p>
    }

    const closeModal = () => setSelectedPaymentId(null);
    const showModal = (paymentId: string) => setSelectedPaymentId(paymentId);

    let modal = null;
    if (selectedPaymentId != null) {
        const payment = payments.find((x) => x.id === selectedPaymentId);
        if (payment) {
            modal = (
                <div className="modal is-active">
                    <div className="modal-background" onClick={closeModal}></div>
                    <div className="modal-content">
                        <PaymentDetails payment={payment} />
                    </div>
                    <button className="modal-close" aria-label="close" onClick={closeModal}></button>
                </div>
            )
        }
    }

    return (
        <>
            <table className="table is-striped is-hoverable is-fullwidth">
                <thead>
                    <tr>
                        <th></th>
                        <th>Date</th>
                        <th>Status</th>
                        <th className="numeric">Amount</th>
                        <th className="numeric">Interest</th>
                        <th className="numeric">Principal</th>
                        <th className="numeric">Penalties</th>
                        <th className="numeric">Escrow</th>
                        <th className="numeric">Fees</th>
                        <th className="numeric">Princial Remaining</th>
                    </tr>
                </thead>
                <tbody>
                    {payments.map((payment) => {
                        console.log(`payment: `, payment)
                        console.log(`paid principal: ${paidPrincipal}`)
                        const total = payment.penalties + payment.interest + payment.principal + payment.fees + payment.escrow;
                        paidPrincipal += payment.principal;
                        return (
                            <tr key={payment.id}>
                                <td>
                                    {payment.transactionHash ? (
                                        <a href="#" onClick={() => showModal(payment.id)}><i className="far fa-eye"></i></a>
                                    ) : null}
                                </td>
                                <td>{format(parseISO(payment.date), 'P')}</td>
                                <td>{payment.status}</td>
                                <td className="numeric">{formatCurrency(total)}</td>
                                <td className="numeric">{formatCurrency(payment.interest)}</td>
                                <td className="numeric">{formatCurrency(payment.principal)}</td>
                                <td className="numeric">{formatCurrency(payment.penalties)}</td>
                                <td className="numeric">{formatCurrency(payment.escrow)}</td>
                                <td className="numeric">{formatCurrency(payment.fees)}</td>
                                <td className="numeric">{formatCurrency(principal - paidPrincipal)}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            {modal}
        </>
    )
};