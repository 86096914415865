import { useContext, useState } from 'react';
import { Formik, Form } from 'formik'
import * as yup from 'yup';
import { AuthContext } from '../../contexts';
import { Field } from '../../components';
import { Link } from 'react-router-dom';

const schema = yup.object().shape({
    email: yup.string().required("Please enter your email address."),
    password: yup.string().required("Please enter your password.")
});

export const SignIn = () => {
    const authContext = useContext(AuthContext);
    const [errors, setErrors] = useState(false);
    return (
    <div className="container">
        <div className="columns is-centered">
            <div className="column is-6">
                <main>
                    <h1 className="title">Sign In</h1>
                    <Formik
                        initialValues={{
                            email: '',
                            password: '',
                        }}
                        validationSchema={schema}
                        onSubmit={async (values) => {
                            try {
                                await authContext.signIn(values.email, values.password);
                                setErrors(false);
                            } catch {
                                setErrors(true);
                            }
                        }}
                    >
                        <Form>
                            <div className="field">
                                <Field name="email" label="Email" required />
                            </div>
                            <div className="field">
                                <Field name="password" label="Password" type="password" required />
                            </div>
                            <div className="field is-grouped">
                                <div className="control">
                                    <button className="button is-link" type="submit">Sign In</button>
                                </div>
                            </div>
                            {errors ? (<p className="is-danger">Invalid email or password.</p>) : null}
                        </Form>
                    </Formik>
                    <p className="mt-4">
                        <Link to="/signup" className="mr-4">Sign Up</Link>
                        <Link to="/forgotPassword">Forgot Password</Link>
                    </p>
                </main>
            </div>
        </div>
    </div>);
}

export default SignIn;