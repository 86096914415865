import { gql, useQuery } from '@apollo/client';
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { UsersIndexQuery } from './__generated__/UsersIndexQuery';

const GET_USERS = gql`
    query UsersIndexQuery {
        users {
            ... on UsersQuerySuccess {
                users {
                    id
                    firstName
                    lastName
                    email
                    address
                }
            }
            ... on UsersQueryError {
                title
                message
            }
        }
    }
`;

export const UserList = () => {
    const { loading, error, data } = useQuery<UsersIndexQuery>(GET_USERS);

    if (loading) {
        return (
            <div className="columns">
                <div className="column">
                    <h2 className="title is-2">Users</h2>
                    [spinner]
                </div>
            </div>
        )
    }

    if (error) {
        console.error(error);
    }

    if (data?.users?.__typename === "UsersQueryError") {
        const errorData = data.users;
        const errorMarkup = (
            <div>
                <h3 className="title is-3">{errorData.title}</h3>
                <p>{errorData.message}</p>
            </div>);
        toast.error(errorMarkup);
        return <div></div>;
    }

    const users = data?.users?.users!;

    return (
        <div className="columns">
            <div className="column">
                <Link to="/users/create" className="button is-pulled-right mt-2">New User</Link>
                <h2 className="title is-2">Users</h2>
                <table className="table is-striped is-hoverable is-fullwidth">
                    <thead>
                        <tr>
                            <th></th>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Address</th>
                            <th>Email</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map((user) => {
                            return (
                                <tr key={user.id}>
                                    <td className="actions">
                                        <Link to={`/users/${user.id}`}><i className="far fa-eye"></i></Link>
                                        <Link to={`/users/${user.id}/edit`}><i className="fas fa-pencil-alt"></i></Link>
                                    </td>
                                    <td>
                                        {user.firstName}
                                    </td>
                                    <td>
                                        {user.lastName}
                                    </td>
                                    <td>{user.address}</td>
                                    <td>
                                        {user.email}
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}