import { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { AuthContext } from '../../contexts';
import { Verification } from './Verification';
import { Field } from '../../components';

const schema = yup.object().shape({
    firstName: yup.string().required("Please enter your first name."),
    lastName: yup.string().required("Please enter your last name"),
    email: yup.string().email("Please enter a valid email address.")
        .required("Please enter your email address."),
    password: yup.string().required("Please choose a password.")
        .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{12,}$/, 
            {
                message: 'Password must be at least 12 characters and contain capital letters, lowercase letters, and digits.',
                excludeEmptyString: true
            }),
    confirm: yup.string().oneOf([yup.ref('password'), null], "Passwords must match.")
});

export const SignUp = () => {
    const [showVerification, setShowVerification] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const authContext = useContext(AuthContext);
    const navigate = useNavigate();

    const signup = async (username: string, password: string, attributes: object) => {
        try {
            const result = await authContext.signUp(
                username,
                password,
                attributes
            );
            if (result.user) {
                if (!result.userConfirmed) {
                    setShowVerification(true);
                }
            }
        } catch (err: any) {
            console.error('Error signing up', err);
            if (err.toString().indexOf('UsernameExistsException') >= 0) {
                setError('An account with this email address already exists.');
            }
        }
    }

    const resendCode = async (email: string) => {
        await authContext.resendCode(email);
        toast('Verification code resent');
    }

    const confirmSignUp = async (email: string, password: string, code: string) => {
        await authContext.confirmSignUp(email, code);
        await authContext.signIn(email, password);
        navigate("/");
    }

    return (
        <>
            <h1 className="title">Sign Up</h1>
            <Formik 
                initialValues={{
                    firstName: '',
                    lastName: '',
                    email: '',
                    password: '',
                    confirm: ''
                }}
                validationSchema={schema}
                onSubmit={(values) => {
                    signup(values.email, values.password, {
                        given_name: values.firstName,
                        family_name: values.lastName
                    });
                }}>
                {props => (
                showVerification ? (
                    <Verification 
                        onResend={() => resendCode(props.values.email)}
                        onSubmit={(code: string) => confirmSignUp(props.values.email, props.values.password, code)}
                        onCancel={() => setShowVerification(false) } />
                ) : (
                <Form>
                    <div className="field">
                        <Field name="firstName" label="First Name" required />
                    </div>
                    <div className="field">
                        <Field name="lastName" label="Last Name" required />
                    </div>
                    <div className="field">
                        <Field name="email" label="Email" required />
                    </div>
                    <div className="field">
                        <Field name="password" label="Password" type="password" required />
                    </div>
                    <div className="field">
                        <Field name="confirm" label="Confirm Password" type="password" />
                    </div>
                    <div className="field is-grouped">
                        <div className="control">
                            <button className="button is-link" type="submit">Submit</button>
                        </div>
                        <div className="control">
                            <Link className="button is-link is-light" to="/">Cancel</Link>
                        </div>
                    </div>
                    {error ? (<p className="mt-4 is-danger">{error}</p>) : null}
                </Form>
                ))}
            </Formik>
        </>);
}

export default SignUp;