import React from "react";
import { gql, useQuery } from "@apollo/client";
import { useField } from "formik";
import _ from "lodash";
import { GetUsers } from "./__generated__/GetUsers";

const GET_USERS = gql`
    query GetUsers {
        users {
            ... on UsersQuerySuccess {
                users {
                    id
                    firstName
                    lastName
                }
            }
            ... on UsersQueryError {
                title
                message
            }
        }
    }
`;

export interface UserPickerFieldProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
    label: string;
    required: boolean;
}

export const UserPicker = ({ label, required, ...props }: UserPickerFieldProps) => {
    const [field, meta] = useField(props.name!);
    const { data, loading } = useQuery<GetUsers>(GET_USERS);
    if (loading || !data?.users) {
        return <div></div>;
    }

    const containerClass = meta.error && meta.touched ? 
        "control select is-danger" :
        "control select";

    if (data.users.__typename === 'UsersQueryError') {
        return (
            <select>
                <option>Problem loading users</option>
            </select>
        );
    }

    const users = _.sortBy(data.users.users!, ['lastName', 'firstName']);

    return (
        <div className="field">
            <label htmlFor={props.id || props.name} className={required ? "label required" : "label"}>{label}</label>
            <div className={containerClass}>
                <select {...field} {...props}>
                    <option>-Select One-</option>
                    {users.map((user) => 
                    <option value={user.id} key={user.id}>{user.firstName} {user.lastName}</option>)}
                </select>
            </div>
            {meta.touched && meta.error ? (
                <p className="help is-danger">{meta.error}</p>
            ) : null}
        </div>
    );
}