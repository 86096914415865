import { useMemo, useState } from 'react';
import { formatCurrency, formatPercentage } from '../../util';
import { LoanDetails_loan_loan_tokenHolders as TokenHolder } from '../../routes/loans/__generated__/LoanDetails';
import { useRoles } from '../../contexts';
import { TokenHolderDetails } from './TokenHolderDetails';
import { User } from '../user';

export const TokenHolders = ({ loanId, tokenHolders }: { loanId: number, tokenHolders: TokenHolder[] | null}) => {
    const [ selectedTokenHolder, setSelectedTokenHolder ] = useState<number | null>(null);
    const { isAdmin, isLender } = useRoles();
    const totalSupply = useMemo(() => tokenHolders?.reduce((previous, current) => previous + current.balance, 0), [tokenHolders]) ?? 0;

    if (tokenHolders == null || tokenHolders.length === 0) {
        return <p className="mb-4 is-italic">No token holders found.</p>
    }

    const closeModal = () => setSelectedTokenHolder(null);
    const showModal = (index: number) => setSelectedTokenHolder(index);

    let modal = null;
    if (selectedTokenHolder != null) {
        const tokenHolder = tokenHolders[selectedTokenHolder];
        if (tokenHolder) {
            modal = (
                <div className="modal is-active">
                    <div className="modal-background" onClick={closeModal}></div>
                    <div className="modal-content">
                        <TokenHolderDetails loanId={loanId} tokenHolder={tokenHolder} totalSupply={totalSupply} />
                    </div>
                    <button className="modal-close" aria-label="close" onClick={closeModal}></button>
                </div>
            )
        }
    }

    return (
        <>
            <table className="table is-striped is-hoverable">
                <thead>
                    <tr>
                        {(isAdmin || isLender) ? <th></th> : null}
                        <th>Token Holder</th>
                        <th className="numeric">Amount</th>
                        <th className="numeric">Effective Ownership</th>
                    </tr>
                </thead>
                <tbody>
                    {tokenHolders.map((tokenHolder, i) => {
                        return (
                            <tr key={i}>
                                {(isAdmin || isLender) ? (<td>
                                    <a href="#" onClick={() => showModal(i)}><i className="far fa-eye"></i></a>
                                </td>) : null}
                                <td><User user={tokenHolder} /></td>
                                <td className="numeric">{formatCurrency(tokenHolder.balance)}</td>
                                <td className="numeric">{formatPercentage(tokenHolder.balance / totalSupply)}</td>
                            </tr>
                        )
                    })}
                </tbody>
                <tfoot>
                    <tr>
                        <th></th>
                        <th>Total</th>
                        <th className="numeric">{formatCurrency(totalSupply)}</th>
                        <th className="numeric">{formatPercentage(tokenHolders.reduce((prev, current) => current.balance + prev, 0) / totalSupply)}</th>
                    </tr>
                </tfoot>
            </table>
            {modal}
        </>
    )
};