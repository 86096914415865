import { useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { gql, useQuery, useMutation } from '@apollo/client';
import * as yup from 'yup';
import { DollarField } from '../../components/ui';
import { encodeId, toNumber } from '../../util';
import { toast } from 'react-toastify';
import { GetEscrowPaymentInfo } from './__generated__/GetEscrowPaymentInfo';
import { UpdateEscrowPayment } from './__generated__/UpdateEscrowPayment';
import { GET_LOAN } from '../../routes/loans';

export const GET_ESCROW_PAYMENT = gql`
    query GetEscrowPaymentInfo($loanId: ID!) {
        loan(id: $loanId) {
            loan {
                monthlyTaxes
                monthlyInsurance
                paymentAmount
            }
        }
    }`;

export const UPDATE_ESCROW_PAYMENT = gql`
    mutation UpdateEscrowPayment($input: UpdateEscrowInput!) {
        updateEscrow(input: $input)
    }
`

const schema = yup.object().shape({
    insurance: yup.string()
        .test(
            "formatted-number",
            "Insurance must be a number.",
            value => /^(\d{1,}|(\d{1,3}(,\d{3})+))(\.\d{2})?$/.test(value ?? "")
        )
        .required("Insurance is required.")
        .test(
            "min-value",
            "Insurance may not be negative.",
            value => Number(value?.replace(/,/g, '')) >= 0
        ),
    taxes: yup.string()
        .test(
            "formatted-number",
            "Taxes must be a number.",
            value => /^(\d{1,}|(\d{1,3}(,\d{3})+))(\.\d{2})?$/.test(value ?? "")
        )
        .required("Taxes is required.")
        .test(
            "min-value",
            "Taxes may not be negative.",
            value => Number(value?.replace(/,/g, '')) >= 0
        )
});

export const EditEscrowPayment = ({ loanId }: { loanId: string }) => {
    const navigate = useNavigate();
    const [updateEscrow, mutationState] = useMutation<UpdateEscrowPayment>(UPDATE_ESCROW_PAYMENT);

    const { data, loading, error } = useQuery<GetEscrowPaymentInfo>(GET_ESCROW_PAYMENT, {
        variables: { 
            loanId: encodeId('Loan', loanId!)
        }
    });

    if (loading) {
        return <div>Spinner</div>;
    }

    if (error) {
        console.error(error);
        return <div>Sorry, an error occured while loading this loan.</div>;
    }

    const loan = data!.loan!.loan!;

    const calculatePayment = (taxes: string, insurance: string) => {
        return loan.paymentAmount - loan.monthlyInsurance - loan.monthlyTaxes + toNumber(taxes) + toNumber(insurance);
    }

    return (
        <Formik
            initialValues={{ insurance: loan.monthlyInsurance.toFixed(2), taxes: loan.monthlyTaxes.toFixed(2) }}
            validationSchema={schema}
            onSubmit={(values) => {
                const id = encodeId('Loan', loanId!);
                updateEscrow({
                    variables: {
                        input: {
                            loanId: id,
                            monthlyTaxes: Number(values.taxes.replace(/,/g, '')),
                            monthlyInsurance: Number(values.insurance.replace(/,/g, '')),
                        }
                    },
                    refetchQueries: [{ query: GET_LOAN, variables: { loanId: id }}],
                    onCompleted: (result) => {
                        if (result.updateEscrow) {
                            toast.success(`Escrow payment updated.`)
                            navigate(`/loans/${loanId}`);
                        }
                    }
                });
            }}>
            {({ values }) => (
            <Form>
                <div className="columns">
                    <div className="column is-3">
                        <DollarField label="Taxes" placeholder="$100" name="taxes" required helpText="Monthly property tax payment" />
                    </div>
                    <div className="column is-3">
                        <DollarField label="Insurance" placeholder="$100" name="insurance" required
                            helpText="Monthly insurance payment" />
                    </div>
                    <div className="column is-3">
                        <DollarField label="New Monthly Payment" name="payment" type="text" readOnly required 
                            value={calculatePayment(values.taxes, values.insurance)} />
                    </div>
                </div>
                <button className="button is-primary" type="submit" disabled={loading || mutationState.loading}>Update Escrow</button>
            </Form>
            )}
        </Formik>
    )
}