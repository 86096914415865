import { Link, useParams } from 'react-router-dom';
import { gql, useQuery, useMutation } from '@apollo/client';
import { DisplayField } from '../../components/ui';
import { LoanTable, UserReadOnlyData } from '../../components/user';
import { displayError, toastError } from '../../util';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { UserDetails } from './__generated__/UserDetails';
import { DeleteUserMutation } from './__generated__/DeleteUserMutation';

export const GET_USER = gql`
    query UserDetails($userId: ID!) {
        user(id: $userId) {
            ... on UserQuerySuccess {
                user {
                    id
                    authId
                    firstName
                    lastName
                    fullName
                    email
                    mobileNumber
                    address
                    balance
                    ethBalance
                    roles
                    createdAt
                    updatedAt
                    borrowedLoans {
                        loanId
                        property {
                            street1
                            city
                            state
                        }
                    }
                    lentLoans {
                        loanId
                        property {
                            street1
                            city
                            state
                        }
                    }
                }
            }
            ... on UserQueryError {
                title
                message
            }
        }
    }`;

export const DELETE_USER = gql`
    mutation DeleteUserMutation($deleteUserId: ID!) {
        deleteUser(id: $deleteUserId) {
            ... on DeleteUserSuccess {
               id
            }
            ... on DeleteUserError {
                title
                message
            }
        }
    }
`;


export const ViewUser = () => {
    const params = useParams();
    const navigate = useNavigate();
    const { userId } = params;
    const { data, loading, error } = useQuery<UserDetails>(GET_USER, {
        variables: { 
            userId: userId
        }
    });
    const [ deleteUser, deleteUserStatus ] = useMutation<DeleteUserMutation>(DELETE_USER);

    if (loading) {
        return <div>Spinner</div>;
    }

    if (error) {
        console.error(error);
        return <div>Sorry, an error occured while loading this user.</div>;
    }

    if (data?.user?.__typename === 'UserQueryError') {
        return displayError(data.user);
    }

    const user = data!.user!.user;
    const borrowedLoans = data!.user!.user.borrowedLoans;
    const lentLoans = data!.user!.user.lentLoans;

    return (<div className="columns">
        <div className="column">
            <h2 className="title is-2">User</h2>
            <div className="columns">
                <div className="column is-half">
                    <DisplayField label="Name" value={`${user.fullName}`} />
                </div>
                <div className="column is-half">
                    <DisplayField label="Email" value={user.email} />
                </div>
            </div>
            <UserReadOnlyData user={user} />
            {borrowedLoans && borrowedLoans.length ? (
                <>
                    <h3 className="title is-4">
                        Loans
                    </h3>
                    <LoanTable loans={borrowedLoans!} />
                </>
            ) : null}
            {lentLoans && lentLoans.length ? (
                <>
                    <h3 className="title is-4">
                        Lending
                    </h3>
                    <LoanTable loans={lentLoans} />
                </>
            ) : null}
            <button className="button is-danger is-outlined is-pulled-right" 
                disabled={deleteUserStatus.loading}
                onClick={() => {
                    if (window.confirm('Are you sure you want to delete this user?')) {
                        deleteUser({
                            variables: {
                                deleteUserId: user.id
                            },
                            refetchQueries: ['UsersIndexQuery'],
                            onCompleted: (result) => {
                                if (result.deleteUser?.__typename === 'DeleteUserSuccess') {
                                    toast.success(`Deleted ${user.firstName} ${user.lastName}.`);
                                    navigate('/users');
                                } else if (result.deleteUser?.__typename === 'DeleteUserError') {
                                    toastError(result.deleteUser);
                                }
                            }
                        });
                    }
                }}>Delete</button>
            <div className="buttons">
                <Link to={`/users/${userId}/edit`} className="button is-outlined is-link">Edit</Link>
                <Link to="/users" className="button is-outlined">Cancel</Link>
            </div>
        </div>
    </div>);
}
