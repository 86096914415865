import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { Auth } from '@aws-amplify/auth';
import { GRAPHQL_ROOT } from '../config';

const httpLink = createHttpLink({
    uri: GRAPHQL_ROOT,
  });

const authLink = setContext(async (request) => {
  try {
    const session = await Auth.currentSession();

    if (session && session.getIdToken()) {
      const token = session.getIdToken().getJwtToken();

      return {
        headers: {
          authorization: token ? `Bearer ${token}` : undefined,
        },
      };
    }
  } catch { }

  return {};
});

export const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
});
