import { DisplayField, DollarDisplayField } from "..";

export interface Account {
    id: string;
    name: string;
    mask: string;
    subtype: string;
    availableBalance: number | null;
    currentBalance: number | null;
    status: string;
  }

export const AccountInfo = ({account}: {account: Account}) => {
    if (!account) {
        return <p>No linked account.</p>
    }
    return (
        <>
            <div className="columns">
                <div className="column is-3">
                    <DisplayField label="Name" value={`${account.name} (${account.mask})`} />
                </div>
                <div className="column is-3">
                    <DisplayField label="Status" value={account.status} />
                </div>
                <div className="column is-3">
                    <DisplayField label="Type" value={account.subtype} />
                </div>
                <div className="column is-3">
                    <DollarDisplayField label="Balance" value={account.availableBalance || 0} />
                </div>
            </div>
        </>);
}