import { Amplify } from 'aws-amplify';
import { ApolloProvider } from '@apollo/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { AuthProvider, AuthIsSignedOut, AuthIsAdmin, AuthIsLender, AuthIsBorrower } from './contexts';
import { MainLayout, UnauthenticatedLayout } from './layouts';
import { ClaimAccount, ForgotPassword, SignIn, SignUp } from './routes/auth';
import { LoansDashboard, CreateLoan, MakePayment, PayoffStatement, ViewLoan, TransferTokens, EditEscrow } from './routes/loans';
import { Contracts } from './routes/settings';
import { TokenDashboard } from './routes/token';
import { CreateUser, EditUser, UserList, ViewUser } from './routes/users';
import { Home } from './routes/Home';
import { client } from './graphql';
import config from './config';

import 'react-toastify/dist/ReactToastify.css';
import { LoanPayments } from './routes/payments';

Amplify.configure({
  Auth: {
    region: config.awsRegion,
    userPoolId: config.userPoolId,
    userPoolWebClientId: config.userPoolWebClientId,
    identityPoolId: config.identityPoolId,
    mandatorySignIn: true,
    ...(config.domain !== 'localhost' 
      ? {
        cookieStorage: {
          domain: config.domain,
          path: "/",
          expires: 365, //days
          secure: config.secure,
        }
      } : {}),
  }
});

const UnauthenticatedRoutes = () => (
    <Routes>
      <Route path="/" element={<UnauthenticatedLayout />}>
        <Route index element={<SignIn />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/forgotPassword" element={<ForgotPassword />} />
        <Route path="/claim/:claimKey" element={<ClaimAccount />} />
      </Route>
    </Routes>
);

const AdminRoutes = () => (
  <Routes>
    <Route path="/" element={<MainLayout />}>
      <Route index element={<Home />} />
      <Route path="/payments" element={<LoanPayments />} />
      <Route path="/loans" element={<LoansDashboard />} />
      <Route path="/loans/create" element={<CreateLoan />} />
      <Route path="/loans/:loanId/payment" element={<MakePayment />} />
      <Route path="/loans/:loanId/payoff" element={<PayoffStatement />} />
      <Route path="/loans/:loanId/transferTokens/:sellerAddress" element={<TransferTokens />} />
      <Route path="/loans/:loanId/escrow" element={<EditEscrow />} />
      <Route path="/loans/:loanId" element={<ViewLoan />} />
      <Route path="/users" element={<UserList />} />
      <Route path="/users/create" element={<CreateUser />} />
      <Route path="/users/:userId" element={<ViewUser />} />
      <Route path="/users/:userId/edit" element={<EditUser />} />
      <Route path="/token" element={<TokenDashboard />} />
      <Route path="/settings" element={<Contracts />} />
    </Route>
  </Routes>
);

const LenderRoutes = () => (
  <Routes>
    <Route path="/" element={<MainLayout />}>
      <Route index element={<Home />} />
      <Route path="/loans" element={<LoansDashboard />} />
      <Route path="/loans/:loanId/payoff" element={<PayoffStatement />} />
      {/* TODO: Lender's route should not include the seller address, it should be assumed to be the current user */}
      <Route path="/loans/:loanId/transferTokens/:sellerAddress" element={<TransferTokens />} />
      <Route path="/loans/:loanId" element={<ViewLoan />} />
    </Route>
  </Routes>
);

const BorrowerRoutes = () => (
  <Routes>
    <Route path="/" element={<MainLayout />}>
    <Route index element={<Home />} />
      <Route path="/loans" element={<LoansDashboard />} />
      <Route path="/loans/:loanId/payment" element={<MakePayment />} />
      <Route path="/loans/:loanId/payoff" element={<PayoffStatement />} />
      <Route path="/loans/:loanId" element={<ViewLoan />} />
    </Route>
  </Routes>
);

const App = () => (
  <ApolloProvider client={client}>
    <AuthProvider>
      <BrowserRouter>
        <AuthIsAdmin>
          <AdminRoutes />
        </AuthIsAdmin>
        <AuthIsLender>
          <LenderRoutes />
        </AuthIsLender>
        <AuthIsBorrower>
          <BorrowerRoutes />
        </AuthIsBorrower>
        <AuthIsSignedOut>
          <UnauthenticatedRoutes />
        </AuthIsSignedOut>
      </BrowserRouter>
    </AuthProvider>
  </ApolloProvider>
)

export default App;
