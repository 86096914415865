// change this to switch envs "dev" | "local" | "production"
const currentEnvironment: keyof Environments = 'dev'

type Environment<T extends 'dev' | 'prod'> = {
  env: T
  apiRoot: string
  userpoolId: string
  userpoolWebclientId: string
  identityPoolId: string
  domain: string
  secure: boolean
  etherscanBaseUrl: string
}

type Environments = {
  local: Environment<'dev'>
  dev: Environment<'dev'>
  production: Environment<'prod'>
}

const environments: Environments = {
  local: {
    env: 'dev',
    apiRoot: 'http://localhost:8080', //"https://api.pixelmortgagedefi.com",
    userpoolId: 'us-east-1_z3k2XpNRT',
    userpoolWebclientId: '57jlat9u7tp0oildcpb52kmhgq',
    identityPoolId: 'us-east-1:119560c8-0423-408b-99d7-fb506016bbc8',
    domain: 'localhost',
    secure: false,
    //etherscanBaseUrl: 'https://app.tryethernal.com',
    etherscanBaseUrl: 'https://sepolia.etherscan.io',
  },
  dev: {
    env: 'dev',
    apiRoot: 'https://api.pixelmortgagedefi.com',
    userpoolId: 'us-east-1_z3k2XpNRT',
    userpoolWebclientId: '57jlat9u7tp0oildcpb52kmhgq',
    identityPoolId: 'us-east-1:119560c8-0423-408b-99d7-fb506016bbc8',
    domain: 'pixelmortgagedefi.com',
    secure: true,
    etherscanBaseUrl: 'https://sepolia.etherscan.io',
  },
  production: {
    env: 'prod',
    apiRoot: 'https://api.pixelmortgagedefi.com',
    userpoolId: 'us-east-1_z3k2XpNRT',
    userpoolWebclientId: '57jlat9u7tp0oildcpb52kmhgq',
    identityPoolId: 'us-east-1:119560c8-0423-408b-99d7-fb506016bbc8',
    domain: 'pixelmortgagedefi.com',
    secure: true,
    etherscanBaseUrl: 'https://etherscan.io',
  },
}

const environment: Environment<'dev' | 'prod'> =
  environments[currentEnvironment]

//#region Environment-specific

export const API_ENV = environment.env

export const API_ROOT = environment.apiRoot

export const GRAPHQL_ROOT = `${API_ROOT.replace(/\/?$/, '/')}graphql`

export const IDENTITY_POOL_ID = environment.identityPoolId

export const USERPOOL_ID = environment.userpoolId

export const USERPOOL_WEBCLIENT_ID = environment.userpoolWebclientId

export const DOMAIN = environment.domain

export const SECURE = environment.secure

export const ETHERSCAN_BASE_URL = environment.etherscanBaseUrl
//#endregion

//#region Common to all environments
export const LOG_LEVEL = 'DEBUG'

export const AWS_REGION = 'us-east-1'
//#endregion

const config = {
  environment: API_ENV,
  apiRoot: API_ROOT,
  graphqlRoot: GRAPHQL_ROOT,
  identityPoolId: IDENTITY_POOL_ID,
  userPoolId: USERPOOL_ID,
  userPoolWebClientId: USERPOOL_WEBCLIENT_ID,
  domain: DOMAIN,
  secure: SECURE,
  logLevel: LOG_LEVEL,
  awsRegion: AWS_REGION,
  etherscanBaseUrl: ETHERSCAN_BASE_URL,
}

export default config
