import { DisplayField } from "../../components"
import { gql, useQuery } from "@apollo/client"
import { GetSettings } from "./__generated__/GetSettings";
import { getAddressLink } from "../../util";

const GET_SETTINGS = gql`
    query GetSettings {
        settings {
            loanContractAddress
            tokenContractAddress
            network
            token {
                name
                symbol
            }
        }
    }
`;

export const Contracts = () => {
    const { data, loading, error } = useQuery<GetSettings>(GET_SETTINGS);
    if (error) {
        console.error(error);
        return <div>Error</div>;
    }
    
    if (loading) {
        return <div>[Spinner]</div>;
    }

    const settings = data?.settings!;

    return (
        <div className="columns">
            <div className="column">
                <h2 className="title is-2">Settings</h2>
                <h4 className="title is-4">Loan Contract</h4>
                <DisplayField label="Address" value={settings.loanContractAddress} link={getAddressLink(settings.loanContractAddress)} />
                <h4 className="title is-4">Token Contract</h4>
                <div className="columns">
                    <div className="column is-6">
                        <DisplayField label="Address" value={settings.tokenContractAddress} link={getAddressLink(settings.tokenContractAddress)} />
                    </div>
                    <div className="column is-3">
                        <DisplayField label="Name" value={settings.token.name} />
                    </div>
                    <div className="column is-3">
                        <DisplayField label="Symbol" value={settings.token.symbol} />
                    </div>
                </div>
                <h4 className="title is-4">Network</h4>
                <DisplayField label="Name" value={settings.network} />
            </div>
        </div>
    )
}