import { Formik, Form } from 'formik'
import * as yup from 'yup';
import { Field } from '../../components';

const schema = yup.object().shape({
    code: yup.string().required("Please enter your verification code."),
});

interface Props {
    onSubmit: (code: string) => void
    onCancel: () => void
    onResend: () => void
}

export const Verification = ({ onSubmit, onCancel, onResend }: Props ) => {
    return (
        <>
            <h1 className="title">Verify your email</h1>
            <p className="mb-4">Please check your email for a verification code.</p>
            <Formik
                initialValues={{
                    code: ''
                }}
                validationSchema={schema}
                onSubmit={async (values) => {
                    try {
                        onSubmit(values.code);
                    } catch (error) {
                        console.error('Error confirming validation code', error);
                    }
                }}
            >
                <Form>
                    <div className="field">
                        <Field name="code" label="Verification code" required />
                    </div>
                    <div className="field is-grouped">
                        <div className="control">
                            <button className="button is-link" type="submit">Submit</button>
                        </div>
                        <div className="control">
                            <button className="button is-link is-light" onClick={onCancel}>Cancel</button>
                        </div>
                    </div>
                    <p className="mt-4">
                        <a href="javascript:void(0)" onClick={onResend}>Resend code</a>
                    </p>
                </Form>
            </Formik>
        </>
    );
}

export default Verification;