import { toast } from "react-toastify";
import { addMonths, addSeconds } from 'date-fns';
import config from "./config"

const oneYearInSeconds = 60 * 60 * 24 * 365;
const oneMonthInSeconds = oneYearInSeconds / 12;

export const encodeId = (objectType: string, id: number | string) => btoa(`${objectType}.${id}`);
export const decodeId = (encodedId: string) => atob(encodedId).split('.')[1];

const numberFormat = new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2, minimumIntegerDigits: 1 })
const nonDigitsRegex = /[^\d\.]+/g
export const formatNumber = (val: string) => {
    if (val) {
        const digits = val.toString().replace(nonDigitsRegex, '');
        if (digits.length > 0) {
            const n = Number(digits);
            if (!isNaN(n)) {
                return numberFormat.format(n);
            }
        }
    }
    return val;
}

export const dropFractionalCents = (val: number) => Math.floor(val * 100) / 100;

export const formatCurrency = (val: string | number | null) => {
    return (typeof(val) !== 'undefined' && val != null && val !== '') ?
        `$${formatNumber(val.toString())}` : '';
}

export const formatPercentage = (val: string | number | null) => {
    const numericPercentage = Number(val);
    if (!isNaN(numericPercentage)) {
        const displayPercentage = numericPercentage * 100;
        return `${formatNumber(displayPercentage.toString())}%`;
    }
    return '';
}

export const interestDueForPeriod = (balance: number, interestRate: number, period: number) => {
    return dropFractionalCents(balance * interestRate / (oneYearInSeconds / period));
}

export const monthlyInterestDue = (balance: number, interestRate: number) => dropFractionalCents(balance * interestRate / 12);

export const formatPhone = (val: string) => {
    if (val) {
        const digits = val.toString().replace(nonDigitsRegex, '');
        if (digits.length === 10) {
            return `(${digits.substring(0,3)}) ${digits.substring(3, 6)}-${digits.substring(6)}`;
        }
    }
    return val;
}

export const toastError = ({title, message}:{ title: string, message: string }) => {
    toast.error(<div>
        <h3 className="title is-3">{title}</h3>
        <p>{message}</p>
    </div>);
}

export const displayError = ({title, message }: { title:string, message: string}) => {
    return (<div>
        <h3 className="title is-3">{title}</h3>
        <p>{message}</p>
    </div>);
}

export const nextPeriod = (date: Date, period: number) => {
    if (period === oneMonthInSeconds) {
        return addMonths(date, 1);
    }
    return addSeconds(date, period);
}

export const getTransactionLink = (tx: string) => `${config.etherscanBaseUrl}/tx/${tx}`;

export const getAddressLink = (address: string) => `${config.etherscanBaseUrl}/address/${address}`;

export const phoneRegEx = /^\(?\d{3}\)?\D*\d{3}\D*\d{4}$/

export const toNumber = (val: string): number => {
    if (val) {
        if (typeof(val) === 'number') {
            return val;
        }
        return Number(val.toString().replace('$', '').replace(',',''));
    }
    return 0;
}
