import { format, parseISO } from 'date-fns';
import { formatCurrency, getTransactionLink, getAddressLink } from '../../util';
import { LoanDetails_loan_loan_payments, LoanDetails_loan_loan_payments_events, LoanDetails_loan_loan_payments_events_LoanPaymentEvent, LoanDetails_loan_loan_payments_events_TokenTransferEvent } from '../../routes/loans/__generated__/LoanDetails';

export const PaymentDetails = ({payment }: { payment: LoanDetails_loan_loan_payments }) => {
    if (!payment) {
        return <p className="mb-4 is-italic">Payment not found.</p>
    }
    const total = payment.penalties + payment.interest + payment.principal + payment.fees;

    return (
        <div className="p-4" style={{ backgroundColor: '#fff' }}>
            <h1 className="title is-2">Payment Details</h1>
            <table className="table">
                <tbody>
                    <tr>
                        <td>Date</td>
                        <td>{format(parseISO(payment.date), 'P')}</td>
                    </tr>
                    <tr>
                        <td>Principal</td>
                        <td className="numeric">{formatCurrency(payment.principal)}</td>
                    </tr>
                    <tr>
                        <td>Interest</td>
                        <td className="numeric">{formatCurrency(payment.interest)}</td>
                    </tr>
                    <tr>
                        <td>Penalties</td>
                        <td className="numeric">{formatCurrency(payment.penalties)}</td>
                    </tr>
                    <tr>
                        <td>Escrow</td>
                        <td className="numeric">{formatCurrency(payment.escrow)}</td>
                    </tr>
                    <tr>
                        <td>Fees</td>
                        <td className="numeric">{formatCurrency(payment.fees)}</td>
                    </tr>
                    <tr>
                        <td>Total</td>
                        <td className="numeric">{formatCurrency(total)}</td>
                    </tr>
                </tbody>
            </table>
            <h2 className="subtitle is-3">Events</h2>
            <EventsList events={payment.events} />
            <p>
                {payment.transactionHash ? (
                    <a href={getTransactionLink(payment.transactionHash)} target="_blank" rel="noreferrer">View on Etherscan</a>
                ) : null}
            </p>
        </div>
    )
};

export const LoanPaymentEvent = ({ event } : { event: LoanDetails_loan_loan_payments_events_LoanPaymentEvent }) => {
    return (
        <table className="table mb-4">
            <tbody>
                <tr>
                    <th colSpan={2} className="has-text-weight-bold">{event.name}</th>
                </tr>
                <tr>
                    <td>Loan Id</td>
                    <td className="numeric">{event.loanId}</td>
                </tr>
                <tr>
                    <td>Payment Id</td>
                    <td className="numeric">{event.paymentId}</td>
                </tr>
                <tr>
                    <td>Date</td>
                    <td>{format(parseISO(event.date), 'P')}</td>
                </tr>
                <tr>
                    <td>Principal</td>
                    <td className="numeric">{formatCurrency(event.principal)}</td>
                </tr>
                <tr>
                    <td>Interest</td>
                    <td className="numeric">{formatCurrency(event.interest)}</td>
                </tr>
                <tr>
                    <td>Penalties</td>
                    <td className="numeric">{formatCurrency(event.penalties)}</td>
                </tr>
                {/* TODO: shouldn't escrow be on this event? */}
                <tr>
                    <td>Fees</td>
                    <td className="numeric">{formatCurrency(event.fees)}</td>
                </tr>
            </tbody>
        </table>
    );
}

export const TokenTransferEvent = ({ event } : { event: LoanDetails_loan_loan_payments_events_TokenTransferEvent }) => {
    return (
        <table className="table mb-4">
            <tbody>
                <tr>
                    <th colSpan={2} className="has-text-weight-bold">{event.name}</th>
                </tr>
                <tr>
                    <td>From</td>
                    <td>
                        <a href={getAddressLink(event.from)} target="_blank" rel="noreferrer">{event.from}</a>
                    </td>
                </tr>
                <tr>
                    <td>To</td>
                    <td>
                        <a href={getAddressLink(event.to)} target="_blank" rel="noreferrer">{event.to}</a>
                    </td>
                </tr>
                <tr>
                    <td>Amount</td>
                    <td className="numeric">{formatCurrency(event.amount)}</td>
                </tr>
            </tbody>
        </table>
    );
}

export const EventsList = ({ events } : { events: LoanDetails_loan_loan_payments_events[] }) => {
    if (!events || events.length === 0) {
        return <div className="is-italic">No events found.</div>;
    }

    return (
        <div>
            {events.map((event) => {
                switch (event.__typename) {
                    case "LoanPaymentEvent":
                        return <LoanPaymentEvent event={event} />
                    case "TokenTransferEvent":
                        return <TokenTransferEvent event={event} />
                    default:
                        return null;
                }
            })}
        </div>
    )
}