import React from "react";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { gql, useMutation } from "@apollo/client";
import { CheckboxListField, Field, PhoneField } from '../../components/ui';
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { toastError, phoneRegEx } from "../../util";
import { CreateUserMutation } from "./__generated__/CreateUserMutation";
import { Role } from "../../types";

const schema = yup.object().shape({
    firstName: yup.string().required("First name is required."),
    lastName: yup.string().required("Last name is required."),
    email: yup.string().email("Please enter a valid email address.").optional(),
    mobileNumber: yup.string().matches(phoneRegEx, "Please enter a valid phone number.").optional(),
    roles: yup.array().required("Please select at least one role."),
});

const CREATE_USER = gql`
    mutation CreateUserMutation($user: CreateUserInput!) {
        createUser(user: $user) {
            ... on CreateUserSuccess {
                user {
                    id
                    firstName
                    lastName
                    email
                    mobileNumber
                    address
                    roles
                    createdAt
                    updatedAt
                }
            }
            ... on CreateUserError {
                title
                message
            }
        }
    }
`;

const roleOptions = Object.keys(Role).map((key) => ({
    label: key,
    value: key,
}));

export const CreateUser = () => {
    const navigate = useNavigate();
    const [createUser, { loading }] = useMutation<CreateUserMutation>(CREATE_USER);

    return (
        <div className="columns">
            <div className="column">
                <h2 className="title is-2">Create User</h2>
                <Formik
                    initialValues={{
                        firstName: '',
                        lastName: '',
                        email: '',
                        mobileNumber: '',
                        roles: []
                    }}
                    validationSchema={schema}
                    onSubmit={(values) => {
                        createUser({ 
                            variables: {
                                user: {
                                    firstName: values.firstName,
                                    lastName: values.lastName,
                                    email: values.email,
                                    mobileNumber: values.mobileNumber,
                                    roles: values.roles
                                }
                            },
                            refetchQueries: ['UsersIndexQuery'],
                            onCompleted: (result) => {
                                if (result.createUser?.__typename === 'CreateUserSuccess') {
                                    const user = result.createUser.user;
                                    toast.success(`Created ${user.firstName} ${user.lastName}.`);
                                    navigate('/users');
                                } else if (result.createUser?.__typename === 'CreateUserError') {
                                    toastError(result.createUser);
                                }
                            }
                        });
                    }}>
                {({ values }) => (
                    <Form>
                        <div className="columns">
                            <div className="column is-half">
                                <Field label="First Name" name="firstName" required />
                            </div>
                            <div className="column is-half">
                                <Field label="Last Name" name="lastName" required />
                            </div>
                        </div>
                        <div className="columns">
                            <div className="column is-half">
                                <Field label="Email" name="email" />
                            </div>
                            <div className="column is-half">
                                <PhoneField label="Mobile Phone" name="mobileNumber" />
                            </div>
                        </div>
                        <div className="columns">
                            <div className="column is-half">
                                <CheckboxListField label="Roles" name="roles" options={roleOptions} />
                            </div>
                        </div>
                        <div className="buttons">
                            <button className="button is-primary is-outlined" type="submit" disabled={loading}>Save</button>
                            <Link to="/users" className="button is-outlined">Cancel</Link>
                        </div>
                    </Form>
                )}
                </Formik>
            </div>
        </div>
    )
}