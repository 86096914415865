import { Link } from "react-router-dom";
import { DisplayField, DollarDisplayField, PercentDisplayField, User } from "..";
import { useRoles } from "../../contexts";
import { LoanDetails_loan_loan } from "../../routes/loans/__generated__/LoanDetails";
import { getAddressLink } from "../../util";
import { Participation } from "./Participation";
import { ParticipationInterestMessage } from "./ParticipationInterestMessage";
import { TokenHolders } from "./TokenHolders";

export const LoanInfo = ({loan}: {loan: LoanDetails_loan_loan}) => {
    const { isAdmin, isLender } = useRoles();
    return (
        <>
            <h4 className="title is-4">Borrower</h4>
            <div className="columns">
                <div className="column is-3">
                    <DisplayField label="First Name" value={loan.borrowerFirstName} />
                </div>
                <div className="column is-3">
                    <DisplayField label="Last Name" value={loan.borrowerLastName} />
                </div>
            </div>
            <h4 className="title is-4">Property</h4>
            <div className="columns">
                <div className="column">
                    <DisplayField label="" value={
                        loan.property.street1 + "\n" +
                        (loan.property.street2 ? loan.property.street2 + "\n" : "") + 
                        loan.property.city + ", " + loan.property.state + " " + loan.property.zip
                    } />
                </div>
            </div>
            <h4 className="title is-4">Terms</h4>
            <div className="columns">
                <div className="column is-3">
                    <DollarDisplayField label="Principal" value={loan.principal} />
                </div>
                <div className="column is-3">
                    <DisplayField label="Amorization" value={loan.amortization} />
                </div>
                <div className="column is-3">
                    <PercentDisplayField label="Interest Rate" value={loan.interestRate} />
                </div>
            </div>
            <div className="columns">
                <div className="column is-3">
                    <DisplayField label="Late Payment Days" value={loan.latePaymentPeriod} />
                </div>
                <div className="column is-3">
                    <PercentDisplayField label="Late Payment Penalty" value={loan.latePaymentPenalty} />
                </div>
                <div className="column is-3">
                    <DisplayField label="Allow Partial Payemnts" value={loan.allowPartialPayments ? "Yes" : "No"} />
                </div>
            </div>
            <div className="columns">
                <div className="column is-3">
                    <DollarDisplayField label="Taxes" value={loan.monthlyTaxes} />
                </div>
                <div className="column is-3">
                    <DollarDisplayField label="Insurance" value={loan.monthlyInsurance} />
                </div>
                <div className="column is-3">
                    <DollarDisplayField label="Escrow Balance" value={loan.escrowBalance} />
                </div>
            </div>
            <div className="columns">
                <div className="column is-3">
                    <DollarDisplayField label="Servicing Fee" value={loan.fee} />
                </div>
                <div className="column is-3">
                    <DollarDisplayField label="Monthly Payment" value={loan.paymentAmount} />
                </div>
                <div className="column is-3">
                    <DisplayField label="First Payment Due" value={loan.firstPaymentDate} />
                </div>
            </div>
            {isAdmin ? (
                <div className="columns">
                    <div className="column">
                        <Link to={`/loans/${loan.loanId}/escrow`} className="button is-dark is-outlined">Edit Escrow</Link>
                    </div>
                </div>
            ) : null}
            {isAdmin || isLender ? (
                <>
                    <h4 className="title is-4">Servicer</h4>
                    <div className="columns">
                        <div className="column">
                            <div className="control">
                                <label className="label">Servicer</label>
                                <User user={loan.servicer} />
                            </div>
                        </div>
                    </div>
                    <h4 className="title is-4">Token</h4>
                    <div className="columns">
                        <div className="column">
                            <div className="control">
                                <label className="label">Contract</label>
                                <a href={getAddressLink(loan.tokenContractAddress)} target="_blank" rel="noreferrer">{loan.tokenName}</a>
                            </div>
                        </div>
                    </div>
                    <h4 className="title is-4">Source Token Holders</h4>
                    <ParticipationInterestMessage interestRate={loan.interestRate} participants={loan.participants} />
                    <div className="columns">
                        <div className="column">
                            <TokenHolders loanId={loan.loanId} tokenHolders={loan.tokenHolders} />
                        </div>
                    </div>
                    <h4 className="title is-4">Participation Token Holders</h4>
                    <div className="columns">
                        <div className="column">
                            <Participation participants={loan.participants} />
                        </div>
                    </div>
                </>
            ) : null}
        </>);
}