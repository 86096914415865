import { useContext } from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { AuthContext, useRoles } from '../contexts';

import 'react-toastify/dist/ReactToastify.css';

export const MainLayout = () => {
    const authContext = useContext(AuthContext);
    const { isAdmin } = useRoles();
    const navigate = useNavigate();
    const signOut = () => {
        authContext.signOut();
        navigate('/')
    }
    return (
    <div className="container">
        <div className="columns my-2">
            <div className="column is-3">
                <h1 className="title is-4">Pixel Mortgage</h1>
                <aside className="menu">
                    <p className="menu-label">
                        <Link to="/loans/">Loans</Link>
                    </p>
                    {isAdmin ? (
                        <>
                            <p className="menu-label">
                                <Link to="/users/">Users</Link>
                            </p>
                            <p className="menu-label">
                                <Link to="/payments">Payments</Link>
                            </p>
                            <p className="menu-label">
                                <Link to="/settings">Settings</Link>
                            </p>
                        </>
                    ) : null}
                    <p className="menu-label">
                        <a href="#" onClick={signOut}>Sign Out</a>
                    </p>
                </aside>
            </div>
            <div className="column">
            <main>
                <Outlet />
            </main>
            </div>
        </div>
        <ToastContainer position={toast.POSITION.BOTTOM_RIGHT} /> 
    </div>
    );
}
