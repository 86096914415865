import { gql, useQuery } from "@apollo/client"
import { GetLoanPayments } from "./__generated__/GetLoanPayments";
import { format, parseISO } from "date-fns";
import { formatCurrency } from "../../util";
import { Link } from "react-router-dom";

const GET_LOAN_PAYMENTS = gql`
  query GetLoanPayments {
    loanPayments {
      id
      date
      penalties
      principal
      interest
      fees
      escrow
      transactionHash
      status
      transactionId
      loanId
      paymentId
      loan {
        id
        loanId
        borrower
        borrowerFirstName
        borrowerLastName
        property {
          street1
        }
      }
    }
  }
`



export const LoanPayments = () => {
  const { data, loading } = useQuery<GetLoanPayments>(GET_LOAN_PAYMENTS)
  if (loading) {
      return <div>[Spinner]</div>;
  }
  if (data?.loanPayments !== null) {
    const payments = data?.loanPayments ?? []
    return (
      <div className="columns">
        <div className="column">
          <h2 className="title is-2">Payments</h2>
          <table className="table is-striped is-hoverable is-fullwidth">
            <thead>
              <tr>
                <th>Date</th>
                <th>Status</th>
                <th className="numeric">Payment</th>
                <th>Loan</th>
              </tr>
            </thead>
            <tbody>
              {payments.map((payment) => {
                const total = payment.principal + payment.interest + payment.penalties + payment.escrow + payment.fees;
                return (
                  <tr key={payment.id}>
                    <td>{format(parseISO(payment.date), 'P')}</td>
                    <td>{payment.status}</td>
                    <td className="numeric">{formatCurrency(total)}</td>
                    <td>
                      {(payment.loan != null ? 
                      <Link to={`/loans/${payment.loan?.loanId}`}>{payment.loan?.property.street1}</Link>
                      : null)}
                    </td>
                  </tr>
                );
              })}        
            </tbody>
          </table>
        </div>
      </div>
    )
  } else {
    return (<div>Error</div>);
  }

}