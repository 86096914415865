import React from "react";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { gql, useMutation, useQuery } from "@apollo/client";
import { Field, CheckboxListField, PhoneField } from '../../components/ui';
import { LoanTable, UserReadOnlyData } from "../../components/user";
import { toast } from "react-toastify";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toastError, displayError, phoneRegEx, formatPhone } from "../../util";
import { UserDetails } from "./__generated__/UserDetails";
import { EditUserMutation } from "./__generated__/EditUserMutation";
import { DeleteUserMutation } from "./__generated__/DeleteUserMutation";
import { GET_USER, DELETE_USER } from "./ViewUser";
import { Role } from "../../types";
import { SendClaimLinkMutation } from "./__generated__/SendClaimLinkMutation";

const schema = yup.object().shape({
    firstName: yup.string().required("First name is required."),
    lastName: yup.string().required("Last name is required."),
    email: yup.string().email("Please enter a valid email address.").optional(),
    mobileNumber: yup.string().matches(phoneRegEx, "Please enter a valid phone number.").optional(),
    roles: yup.array().required("Please select at least one role."),
});

const EDIT_USER = gql`
    mutation EditUserMutation($user: EditUserInput!) {
        editUser(user: $user) {
            ... on EditUserSuccess {
                user {
                    id
                    firstName
                    lastName
                }
            }
            ... on EditUserError {
                title
                message
            }
        }
    }
`;

const SEND_CLAIM_LINK = gql`
    mutation SendClaimLinkMutation($id: ID!) {
        sendClaimLink(id: $id)
    }
`

const roleOptions = Object.keys(Role).map((key) => ({
    label: key,
    value: key,
}));

export const EditUser = () => {
    const params = useParams();
    const navigate = useNavigate();
    const { userId } = params;
    const [editUser, editUserStatus] = useMutation<EditUserMutation>(EDIT_USER);
    const { data, loading, error } = useQuery<UserDetails>(GET_USER, {
        variables: { 
            userId: userId,
        }
    });
    const [ deleteUser, deleteUserStatus ] = useMutation<DeleteUserMutation>(DELETE_USER);
    const [ sendClaimLink, sendClaimLinkStatus ] = useMutation<SendClaimLinkMutation>(SEND_CLAIM_LINK, {
        variables: {
            id: userId,
        }
    });

    const onSendClaimLink = async () => {
        try {
            const result = await sendClaimLink();
            if (result.data) {
                toast.success(`Sent a link to claim this account to ${user.email}.`);
            }
        } catch (e) {
            console.error(e);
            toastError({ title: 'Error', message: 'There was a problem sending a claim link. Please try again'});
        }
    }

    if (loading) {
        return <div>Spinner</div>;
    }

    if (error) {
        console.error(error);
        return <div>Sorry, an error occured while loading this user.</div>;
    }

    if (data?.user?.__typename === 'UserQueryError') {
        return displayError(data.user);
    }

    const user = data!.user!.user;
    const borrowedLoans = data!.user!.user.borrowedLoans;
    const lentLoans = data!.user!.user.lentLoans;

    return (
        <div className="columns">
            <div className="column">
                <h2 className="title is-2">Edit User</h2>
                <Formik
                    initialValues={{
                        firstName: user.firstName,
                        lastName: user.lastName,
                        email: user.email,
                        mobileNumber: formatPhone(user.mobileNumber ?? ""),
                        roles: user.roles,
                    }}
                    validationSchema={schema}
                    onSubmit={(values) => {
                        editUser({ 
                            variables: {
                                user: {
                                    id: userId,
                                    firstName: values.firstName,
                                    lastName: values.lastName,
                                    email: values.email,
                                    mobileNumber: values.mobileNumber,
                                    roles: values.roles,
                                }
                            },
                            refetchQueries: ['UsersIndexQuery'],
                            onCompleted: (result) => {
                                if (result.editUser?.__typename === 'EditUserSuccess') {
                                    const updatedUser = result.editUser.user;
                                    toast.success(`Created ${updatedUser.firstName} ${updatedUser.lastName}.`);
                                    navigate('/users');
                                } else if (result.editUser?.__typename === 'EditUserError') {
                                    toastError(result.editUser);
                                }
                            }
                        });
                    }}>
                {({ values }) => (
                    <Form>
                        <div className="columns">
                            <div className="column is-half">
                                <Field label="First Name" name="firstName" required />
                            </div>
                            <div className="column is-half">
                                <Field label="Last Name" name="lastName" required />
                            </div>
                        </div>
                        <div className="columns">
                            <div className="column is-half">
                                <Field label="Email" name="email" />
                            </div>
                            <div className="column is-half">
                                <PhoneField label="Mobile Phone" name="mobileNumber" />
                            </div>
                        </div>
                        <div className="columns">
                            <div className="column is-half">
                                <CheckboxListField label="Roles" name="roles" options={roleOptions} />
                            </div>
                        </div>
                        <UserReadOnlyData user={user} />
                        {borrowedLoans && borrowedLoans.length ? (
                            <>
                                <h3 className="title is-4">
                                    Loans
                                </h3>
                                <LoanTable loans={borrowedLoans!} />
                            </>
                        ) : null}
                        {lentLoans && lentLoans.length ? (
                            <>
                                <h3 className="title is-4">
                                    Lending
                                </h3>
                                <LoanTable loans={lentLoans} />
                            </>
                        ) : null}
                        {user.authId == null ? (
                        <div className="columns">
                            <div className="column is-half">
                                <button className="button is-outlined" disabled={sendClaimLinkStatus.loading} onClick={onSendClaimLink}>Send Claim Link</button>
                            </div>
                        </div>
                        ) : null}
                        <button className="button is-danger is-outlined is-pulled-right" 
                            disabled={deleteUserStatus.loading}
                            onClick={() => {
                                if (window.confirm('Are you sure you want to delete this user?')) {
                                    deleteUser({
                                        variables: {
                                            deleteUserId: user.id
                                        },
                                        refetchQueries: ['UsersIndexQuery'],
                                        onCompleted: (result) => {
                                            if (result.deleteUser?.__typename === 'DeleteUserSuccess') {
                                                toast.success(`Deleted ${user.firstName} ${user.lastName}.`);
                                                navigate('/users');
                                            } else if (result.deleteUser?.__typename === 'DeleteUserError') {
                                                toastError(result.deleteUser);
                                            }
                                        }
                                    });
                                }
                            }}>Delete</button>
                        <div className="buttons">
                            <button className="button is-primary is-outlined" type="submit" disabled={editUserStatus.loading || deleteUserStatus.loading}>Save</button>
                            <Link to="/users" className="button is-outlined">Cancel</Link>
                        </div>
                    </Form>
                )}
                </Formik>
            </div>
        </div>
    )
}