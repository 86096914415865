import React from 'react';
import { Link } from 'react-router-dom';
import { HelpText } from './Field';
import { formatCurrency, formatPercentage } from '../../util';

export interface DisplayFieldProps extends React.HTMLAttributes<HTMLDivElement> {
    label?: string;
    value: string | number | null;
    col?: string;
    format?: (val: string) => string;
    helpText?: string;
    link?: string;
}

export const DisplayField = ({ label, value, col, format, helpText, link, ...props}: DisplayFieldProps) => {
    const valueWithBreaks = value ? value.toString().replace('\n', "<br/>") : '';
    const formattedValue = format ? format(valueWithBreaks) : valueWithBreaks;
    const input = (
        <div className="control">
            <div {...props} dangerouslySetInnerHTML={{ __html: formattedValue }}></div>
        </div>);

    let markup = input;
    if (link) {
        if (link.toLowerCase().startsWith('http')) {
            markup = <a href={link} target="_blank">{input}</a>;
        } else {
            markup = <Link to={link}>{input}</Link>;
        }
    }

    return (
        <div className="field">
            {label && label.length > 0 ? (
                <label className="label">
                    {label}
                    <HelpText content={helpText} />
                </label>
            ) : null}
            {col ? (
                <div className="columns is-gapless mb-0">
                    <div className={`column ${col}`}>
                        {markup}
                    </div>
                </div>
            ) : markup}
        </div>
    );
}

export const PercentDisplayField = ({ value, ...props}: DisplayFieldProps) => {
    return (
        <DisplayField value={formatPercentage(value)} {...props} />
    )
}

export const DollarDisplayField = ({ value, ...props}: DisplayFieldProps) => {
    return (
        <DisplayField value={formatCurrency(value)} {...props} />
    )
}