import { useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { gql, useQuery, useMutation } from '@apollo/client';
import * as yup from 'yup';
import { DollarDisplayField, DollarField } from '../../components/ui';
import { encodeId, toNumber } from '../../util';
import { toast } from 'react-toastify';
import { GET_LOAN } from '../../routes/loans';
import { GetEscrowBalance } from './__generated__/GetEscrowBalance';
import { UpdateEscrowBalance } from './__generated__/UpdateEscrowBalance';

export const GET_ESCROW_BALANCE = gql`
    query GetEscrowBalance($loanId: ID!) {
        loan(id: $loanId) {
            loan {
                escrowBalance
            }
        }
    }`;

export const UPDATE_ESCROW_BALANCE = gql`
    mutation UpdateEscrowBalance($input: EscrowDistributionInput!) {
        recordEscrowDistribution(input: $input)
    }
`

const schema = yup.object().shape({
    amount: yup.string()
        .test(
            "formatted-number",
            "Amount must be a number.",
            value => /^(\d{1,}|(\d{1,3}(,\d{3})+))(\.\d{2})?$/.test(value ?? "")
        )
        .test(
            "min-value",
            "Amount must be at least $0.",
            value => Number(value?.replace(/,/g, '')) >= 0
        )
        .required("Amount is required."),
});

export const EditEscrowBalance = ({ loanId }: { loanId: string }) => {
    const navigate = useNavigate();
    const [updateBalance, mutationState] = useMutation<UpdateEscrowBalance>(UPDATE_ESCROW_BALANCE);

    const { data, loading, error } = useQuery<GetEscrowBalance>(GET_ESCROW_BALANCE, {
        variables: { 
            loanId: encodeId('Loan', loanId!)
        }
    });

    if (loading) {
        return <div>Spinner</div>;
    }

    if (error) {
        console.error(error);
        return <div>Sorry, an error occured while loading this loan.</div>;
    }

    const loan = data!.loan!.loan!;

    return (
        <Formik
            initialValues={{ amount: '' }}
            validationSchema={schema}
            onSubmit={(values) => {
                const id = encodeId('Loan', loanId!);
                updateBalance({
                    variables: {
                        input: {
                            loanId: id,
                            amount: Number(values.amount.replace(/,/g, '')),
                        }
                    },
                    refetchQueries: [{ query: GET_LOAN, variables: { loanId: id }}],
                    onCompleted: (result) => {
                        if (result.recordEscrowDistribution) {
                            toast.success(`Escrow account balance updated.`)
                            navigate(`/loans/${loanId}`);
                        }
                    }
                });
            }}>
            {({ values }) => (
            <Form>
                <div className="columns">
                    <div className="column is-3">
                        <DollarDisplayField label="Current Balance" value={loan.escrowBalance} /> 
                    </div>
                </div>
                <div className="columns">
                    <div className="column is-3">
                        <DollarField label="Payment Amount" placeholder="$1000" name="amount" required helpText="The amount distributed from the escrow account." />
                    </div>
                </div>
                <div className="columns">
                    <div className="column is-3">
                        <DollarDisplayField label="New Balance" value={loan.escrowBalance - toNumber(values.amount)} /> 
                    </div>
                </div>
                <button className="button is-primary" type="submit" disabled={loading || mutationState.loading}>Save</button>
            </Form>
            )}
        </Formik>
    )
}