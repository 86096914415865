import { DisplayField, DollarDisplayField } from '../ui';
import { DateTime } from 'luxon';
import { getAddressLink } from '../../util';

interface UserDetails {
    address: string | null,
    balance: number,
    ethBalance: number,
    createdAt: any,
    updatedAt: any,
};

export const UserReadOnlyData = ({ user }: { user: UserDetails }) => {
    const created = DateTime.fromISO(user.createdAt);
    const updated = DateTime.fromISO(user.updatedAt);
    return (
        <>
            <DisplayField label="Wallet Address" value={user.address} link={user.address ? getAddressLink(user.address!) : undefined} />
            <div className="columns">
                <div className="column is-half">
                    <DollarDisplayField label="Balance" value={user.balance} />
                </div>
                <div className="column is-half">
                    <DisplayField label="ETH" value={user.ethBalance} />
                </div>
            </div>
            <div className="columns">
                <div className="column is-half">
                    <DisplayField label="Created" value={created.toLocaleString(DateTime.DATETIME_SHORT)} />
                </div>
                <div className="column is-half">
                    <DisplayField label="Updated" value={updated.toLocaleString(DateTime.DATETIME_SHORT)} />
                </div>
            </div>
        </>
    );
}