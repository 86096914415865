import { useState, useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { LoanIndexQuery_loans_loans } from './__generated__/LoanIndexQuery'

const GET_LOANS = gql`
    query LoanIndexQuery {
        loans {
            loans {
                id
                loanId
                borrowerFirstName
                borrowerLastName
                property {
                    street1
                    street2
                    city
                    state
                    zip
                }
            }
        }
    }
`;

export const LoansDashboard = () => {
    const [ sortedLoans, setSortedLoans ] = useState<LoanIndexQuery_loans_loans[]>([]);
    const { loading, error, data } = useQuery(GET_LOANS);

    useEffect(() => {
        if (data && data?.loans?.loans) {
            const sorted = _.orderBy(data.loans.loans, ['borrowerLastName', 'borrowerFirstName']);
            setSortedLoans(sorted);
        }
    }, [data, setSortedLoans]);

    if (loading) {
        return (
            <div className="columns">
                <div className="column">
                    <h2 className="title is-2">Loans</h2>
                    [spinner]
                </div>
            </div>
        )
    }

    if (error) {
        console.error(error);
    }

    return (
        <div className="columns">
            <div className="column">
                <Link to="/loans/create" className="button is-pulled-right mt-2">New Loan</Link>
                <h2 className="title is-2">Loans</h2>
                <table className="table is-striped is-hoverable is-fullwidth">
                    <thead>
                        <tr>
                            <th></th>
                            <th>ID</th>
                            <th>Borrower</th>
                            <th>Property</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sortedLoans.map((loan) => {
                            return (
                                <tr key={loan.id}>
                                    <td><Link to={`/loans/${loan.loanId}`}><i className="far fa-eye"></i></Link></td>
                                    <td>{loan.loanId}</td>
                                    <td>
                                        {loan.borrowerLastName}, {loan.borrowerFirstName}
                                    </td>
                                    <td>
                                        {loan.property.street1} {loan.property.city}, {loan.property.state}
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}