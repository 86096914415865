import React from "react";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { gql, useMutation } from "@apollo/client";
import { Checkbox, Field, PercentField, DollarField, StateField, PeriodField, UserPicker } from '../../components/ui';
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { toastError } from "../../util";
import { CreateLoanMutation } from "./__generated__/CreateLoanMutation";

const schema = yup.object().shape({
    borrowerId: yup.string().required("Please selelct a borrower"),
    lenderId: yup.string().required("Please select a lender."),
    street1: yup.string().required("Street address is required."),
    street2: yup.string().optional(),
    city: yup.string().required("City is required."),
    state: yup.string().required("State is required."),
    zip: yup.string().required("Zip code is required.")
        .matches(/\d{5}(-\d{4})?/, "Please enter a valid zip code."),
    principal: yup.string()
        .test(
            "formatted-number",
            "Principal must be a number.",
            value => /^(\d{1,}|(\d{1,3}(,\d{3})+))(\.\d{2})?$/.test(value ?? "")
        )
        .test(
            "min-value",
            "Principal must be at least 100.",
            value => Number(value?.replace(/,/g, '')) >= 100
        )
        .required("Principal is required."),
    amortization: yup.number().integer("Amortization must be a whole number.")
        .positive("Amortization must be a positive number.")
        .required("Amortization is required.")
        .typeError("Amortization must be a number."),
    period: yup.number().integer("Period must be a whole number.")
        .positive("Period must be a positive number.")
        .required("Period is required.")
        .typeError("Period must be a number."),
    interestRate: yup.number()
        .min(0, "Interest rate must be at least 0%.")
        .max(18, "Interest rate may not be more than 18%.")
        .required("Interest rate is required.")
        .typeError("Interest rate must be a number."),
    latePaymentDays: yup.number()
        .integer("Late payment days must be a whole number.")
        .positive("Late payment days must be a postive number.")
        .required("Late payment days is required.")
        .typeError("Late payment days must be a number."),
    latePaymentPenalty: yup.number()
        .min(0, "Late payment penalty must be at least 0%.")
        .max(10, "Late payment penalty may not be more than 10%.")
        .required("Late payment penalty is required.")
        .typeError("Late payment penalty must be a number."),
    fee: yup.string()
        .test(
            "formatted-number",
            "Servicing Fee must be a number.",
            value => /^(\d{1,}|(\d{1,3}(,\d{3})+))(\.\d{2})?$/.test(value ?? "")
        )
        .test(
            "min-value",
            "Servicing Fee must be at least $0.",
            value => Number(value?.replace(/,/g, '')) >= 0
        )
        .required("Servicing Fee is required."),
    monthlyTaxes: yup.string()
        .test(
            "formatted-number",
            "Taxes must be a number.",
            value => /^(\d{1,}|(\d{1,3}(,\d{3})+))(\.\d{2})?$/.test(value ?? "")
        )
        .test(
            "min-value",
            "Taxes must be at least $0.",
            value => Number(value?.replace(/,/g, '')) >= 0
        )
        .required("Taxes is required."),
    monthlyInsurance: yup.string()
        .test(
            "formatted-number",
            "Insurance must be a number.",
            value => /^(\d{1,}|(\d{1,3}(,\d{3})+))(\.\d{2})?$/.test(value ?? "")
        )
        .test(
            "min-value",
            "Insurance must be at least $0.",
            value => Number(value?.replace(/,/g, '')) >= 0
        )
        .required("Insurance is required."),
    escrowBalance: yup.string()
        .test(
            "formatted-number",
            "Escrow Balance must be a number.",
            value => /^(\d{1,}|(\d{1,3}(,\d{3})+))(\.\d{2})?$/.test(value ?? "")
        )
        .test(
            "min-value",
            "Escrow Balance must be at least $0.",
            value => Number(value?.replace(/,/g, '')) >= 0
        )
        .required("Escrow Balance is required."),
    firstPaymentDue: yup.date()
        .required("First payment date is required.")
        .typeError("Please enter a valid date."),
    allowPartialPayments: yup.bool()
});

const initialValues: Values = {
    borrowerId: '',
    lenderId: '',
    street1: '',
    street2: '',
    city: '',
    state: '',
    zip: '',
    principal: '',
    amortization: '',
    period: '2628000',
    interestRate: '',
    latePaymentDays: '',
    latePaymentPenalty: '',
    firstPaymentDue: '',
    fee: '25',
    monthlyTaxes: '',
    monthlyInsurance: '',
    escrowBalance: '',
    payment: '',
    allowPartialPayments: false,
}

interface Values {
    borrowerId: string,
    lenderId: string,
    street1: string;
    street2: string;
    city: string;
    state: string;
    zip: string;
    principal: string;
    amortization: string;
    period: string;
    interestRate: string;
    latePaymentDays: string;
    latePaymentPenalty: string;
    firstPaymentDue: string;
    payment: string;
    fee: string;
    monthlyTaxes: string;
    monthlyInsurance: string;
    escrowBalance: string;
    allowPartialPayments: boolean;
}

type AddressPlaceholder = {
    street1: string;
    street2: string;
    city: string;
    zip: string;
}

const placeholders: AddressPlaceholder[] = [
    {
        street1: "4 Privet Drive",
        street2: "The cupboard under the stairs",
        city: "Little Whinging",
        zip: "58785"
    },
    {
        street1: "1640 Riverside Drive",
        street2: "",
        city: "Hill Valley",
        zip: "90650"
    },
    {
        street1: "12 Grimmauld Place",
        street2: "",
        city: "London",
        zip: "93618"
    },
    {
        street1: "129 West 81st St",
        street2: "Apartment 5A",
        city: "New York",
        zip: "10001"
    },
    {
        street1: "22630 Hegal Place",
        street2: "Apartment 42",
        city: "Alexandria",
        zip: "23242"
    },
    {
        street1: "742 Evergreen Terrace",
        street2: "",
        city: "Springfield",
        zip: "65619"
    },
    {
        street1: "42 Wallaby Way",
        street2: "",
        city: "Sydney",
        zip: "33587"
    },
];

const oneYearInSeconds = 60 * 60 * 24 * 365;
const calculatePayment = (principal: number, interest: number, fee: number, taxes: number, insurance: number, term: number, period: number): number => {
    // M = P[r(1+r)^n/((1+r)^n)-1)]
    const r = interest / 100 / (oneYearInSeconds / period);
    const n = term;
    const p = principal;
    try {
        let payment = p * ((r * Math.pow(1 + r, n)) / (Math.pow(1 + r, n) - 1));
        if (isNaN(payment)) {
            return 0;
        }
        payment = payment + taxes + insurance + fee;
        return (Math.round((payment + Number.EPSILON) * 100) / 100);
    } catch {
        return 0;
    }
}

const toNumber = (val: string): number => {
    if (val) {
        return Number(val.replace(',',''));
    }
    return 0;
}

const CREATE_LOAN = gql`
    mutation CreateLoanMutation($loan: CreateLoanInput!) {
        createLoan(loan: $loan) {
            ... on CreateLoanSuccess {
                loan {
                    id
                    loanId
                }
            }
            ... on CreateLoanError {
                title
                message
            }
        }
    }
`;

export const CreateLoan = () => {
    const n = Math.floor(Math.random() * placeholders.length);
    const placeholder = placeholders[n];

    const navigate = useNavigate();
    const [createLoan, { loading }] = useMutation<CreateLoanMutation>(CREATE_LOAN);

    return (
        <div className="columns">
            <div className="column">
                <h2 className="title is-2">Create Loan</h2>
                <Formik
                    initialValues={initialValues}
                    validationSchema={schema}
                    onSubmit={(values) => {
                        const calculatedPayment = calculatePayment(toNumber(values.principal), toNumber(values.interestRate), toNumber(values.fee), toNumber(values.monthlyTaxes), toNumber(values.monthlyInsurance), toNumber(values.amortization), toNumber(values.period ));
                        createLoan({ 
                            variables: {
                                loan: {
                                    lenderId: values.lenderId,
                                    borrowerId: values.borrowerId,
                                    street1: values.street1,
                                    street2: values.street2,
                                    city: values.city,
                                    state: values.state,
                                    zip: values.zip,
                                    principal: Number(values.principal.replace(/,/g, '')),
                                    amortization: parseInt(values.amortization),
                                    period: parseInt(values.period),
                                    interestRate: Number(values.interestRate) / 100,
                                    latePaymentPeriod: parseInt(values.latePaymentDays),
                                    latePaymentPenalty: Number(values.latePaymentPenalty) / 100,
                                    fee: Number(values.fee.replace(/,/g, '')),
                                    monthlyTaxes: Number(values.monthlyTaxes.replace(/,/g, '')),
                                    monthlyInsurance: Number(values.monthlyInsurance.replace(/,/g, '')),
                                    escrowBalance: Number(values.escrowBalance.replace(/,/g, '')),
                                    firstPaymentDate: values.firstPaymentDue,
                                    payment: calculatedPayment,
                                    allowPartialPayments: values.allowPartialPayments,
                                }
                            },
                            refetchQueries: ['LoanIndexQuery'],
                            onCompleted: (result) => {
                                if (result.createLoan?.__typename === 'CreateLoanSuccess') {
                                    toast.success(`Created Loan #${result.createLoan.loan.loanId}`);
                                    navigate('/loans');
                                } else if (result.createLoan?.__typename === 'CreateLoanError') {
                                    toastError(result.createLoan);
                                }
                            }
                        });
                    }}>
                {({ values }) => (
                    <Form>
                        <h4 className="title is-4">Participants</h4>
                        <div className="columns">
                            <div className="column is-half">
                                <UserPicker label="Lender" name="lenderId" required />
                            </div>
                            <div className="column">
                                <UserPicker label="Borrower" name="borrowerId" required />
                            </div>
                        </div>

                        <h4 className="title is-4">Property</h4>
                        <Field label="Streeet Address" placeholder={placeholder.street1} name="street1" required />
                        <Field label="Apartment or suite" placeholder={placeholder.street2} name="street2" />
                        <div className="columns">
                            <div className="column is-7">
                                <Field label="City" placeholder={placeholder.city} name="city" required />
                            </div>
                            <div className="column is-3">
                                <StateField label="State" name="state" required />
                            </div>
                            <div className="column is-2">
                                <Field label="Zip" placeholder={placeholder.zip} name="zip" required />
                            </div>
                        </div>

                        <h4 className="title is-4">Terms</h4>
                        <div className="columns">
                            <div className="column is-6">
                                <DollarField label="Principal" placeholder="100,000" name="principal" required />
                            </div>
                            <div className="column is-2">
                                <PercentField label="Interest Rate" placeholder="5.0" name="interestRate" required />
                            </div>
                        </div>
                        <div className="columns">
                            <div className="column is-3">
                                <Field label="Amortization" placeholder="360" name="amortization" type="text" required
                                    helpText="Number of periods in the loan." />
                            </div>
                            <div className="column is-3">
                                <PeriodField label="Period" name="period" required />
                            </div>
                        </div>

                        <div className="columns">
                            <div className="column is-3">
                                <Field label="Late Payment Days" placeholder="15" name="latePaymentDays" type="text" required
                                    helpText="Number of days after payment due date before late payment penalty is applied" />
                            </div>
                            <div className="column is-3">
                                <PercentField label="Late Payment Penalty" placeholder="3.0" name="latePaymentPenalty" required
                                    helpText="Percentage of the amount due that will be charged as a penalty if the payment is not made on or before the due date." />
                            </div>
                            <div className="column is-3">
                                <Checkbox label="Allow Partial Payments" name="allowPartialPayments" />
                            </div>
                        </div>
                        <div className="columns">
                            <div className="column is-3">
                                <DollarField label="Taxes" placeholder="$100" name="monthlyTaxes" required helpText="Monthly property tax payment" />
                            </div>
                            <div className="column is-3">
                                <DollarField label="Insurance" placeholder="$100" name="monthlyInsurance" required
                                    helpText="Monthly insurance payment" />
                            </div>
                            <div className="column is-3">
                                <DollarField label="Escrow Balance" placeholder="$1,000" name="escrowBalance" required
                                    helpText="Initial balance of escrow account" />
                            </div>
                        </div>
                        <div className="columns">
                            <div className="column is-3">
                                <DollarField label="Servicing Fee" placeholder="$25" name="fee" required />
                            </div>
                            <div className="column is-3">
                                <DollarField label="Payment" name="payment" type="text" readOnly required 
                                    value={calculatePayment(toNumber(values.principal), toNumber(values.interestRate), toNumber(values.fee), toNumber(values.monthlyTaxes), toNumber(values.monthlyInsurance), toNumber(values.amortization), toNumber(values.period ))} />
                            </div>
                            <div className="column is-3">
                                <Field label="First Payment Due" name="firstPaymentDue" type="date" required />
                            </div>
                        </div>
                        <button className="button is-primary" type="submit" disabled={loading}>Create Loan</button>
                    </Form>
                )}
                </Formik>
            </div>
        </div>
    )
}