import { formatCurrency, formatPercentage } from '../../util';
import { LoanDetails_loan_loan_tokenHolders } from '../../routes/loans/__generated__/LoanDetails';
import { Link } from 'react-router-dom';
import { User } from '../user';

export const TokenHolderDetails = ({ loanId, totalSupply, tokenHolder }: { loanId: number, totalSupply: number, tokenHolder: LoanDetails_loan_loan_tokenHolders }) => {
    if (!tokenHolder) {
        return <p className="mb-4 is-italic">Token holder not found.</p>
    }

    return (
        <div className="p-4" style={{ backgroundColor: '#fff' }}>
            <h1 className="title is-2">Token Holder Details</h1>
            <h3 className="title is-3"><User user={tokenHolder} /></h3>
            <table className="table">
                <tbody>
                    <tr>
                        <td>Balance</td>
                        <td className="numeric">{formatCurrency(tokenHolder.balance)}</td>
                    </tr>
                    <tr>
                        <td>Principal</td>
                        <td className="numeric">{formatCurrency(totalSupply)}</td>
                    </tr>
                    <tr>
                        <td>Ownership</td>
                        <td className="numeric">{formatPercentage(tokenHolder.balance / totalSupply)}</td>
                    </tr>
                </tbody>
            </table>
            <div className="columns">
                <div className="column">
                    <Link to={`/loans/${loanId}/transferTokens/${tokenHolder.address}`} className="button is-dark is-outlined">Sell Tokens</Link>
                </div>
            </div>
        </div>
    )
};
