import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { EditEscrowBalance } from '../../components/loan';
import { EditEscrowPayment } from '../../components/loan';

enum Tabs { MonthlyPayment, EscrowBalance };

export const EditEscrow = () => {
    const params = useParams();
    const { loanId } = params;
    const [activeTab, setActiveTab] = useState<Tabs>(Tabs.MonthlyPayment);

    return (
        <div className="columns">
            <div className="column">
                <h2 className="title is-2">Edit Escrow</h2>
                <div className="tabs">
                    <ul>
                        <li className={activeTab === Tabs.MonthlyPayment ? "is-active" : ""}><a onClick={() => setActiveTab(Tabs.MonthlyPayment)}>Monthly Payment</a></li>
                        <li className={activeTab === Tabs.EscrowBalance ? "is-active" : ""}><a onClick={() => setActiveTab(Tabs.EscrowBalance)}>Escrow Balance</a></li>
                    </ul>
                </div>
                <div className={activeTab === Tabs.MonthlyPayment ? "" : "is-hidden"}>
                    <EditEscrowPayment loanId={loanId!} />
                </div>
                <div className={activeTab === Tabs.EscrowBalance ? "" : "is-hidden"}>
                    <EditEscrowBalance loanId={loanId!} />
                </div>
            </div>
        </div>
    )
}
