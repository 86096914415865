import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

export const UnauthenticatedLayout = () => {
    return (
    <div className="container">
        <div className="columns is-centered">
            <div className="column is-6">
                <main>
                    <Outlet />
                </main>
            </div>
        </div>
        <ToastContainer /> 
    </div>
  );
}
