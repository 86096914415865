import { Link, useParams } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { DollarDisplayField } from '../../components/ui';
import { encodeId } from '../../util';
import { LoanInfo, FuturePayments, PaymentHistory } from '../../components/loan';
import { LoanDetails } from './__generated__/LoanDetails';
import { useRoles } from '../../contexts';

export const GET_LOAN = gql`
    query LoanDetails($loanId: ID!) {
        loan(id: $loanId) {
            loan {
                id
                loanId
                borrower
                borrowerFirstName
                borrowerLastName
                property {
                    street1
                    street2
                    city
                    state
                    zip
                }
                servicer {
                    address
                    user {
                        id
                        fullName
                    }
                }
                principal
                amortization
                period
                interestRate
                latePaymentPeriod
                latePaymentPenalty
                fee
                monthlyTaxes
                monthlyInsurance
                escrowBalance
                firstPaymentDate
                allowPartialPayments
                paymentAmount
                balance
                payments {
                    id
                    date
                    penalties
                    principal
                    interest
                    fees
                    escrow
                    transactionHash
                    transactionId
                    status
                    paymentId
                    events {
                        ... on LoanPaymentEvent {
                            loanId
                            paymentId
                            date
                            penalties
                            principal
                            interest
                            fees
                            name
                        }
                        ... on TokenTransferEvent {
                            name
                            from
                            to
                            amount
                        }
                    }
                }
                dueDate
                duePrincipal
                dueInterest
                duePenalties
                dueFees
                dueEscrow
                participants {
                    loanId
                    address
                    interestRate
                    dollarAmount
                    effectiveOwnership
                    user {
                        id
                        fullName
                    }
                }
                tokenContractAddress
                tokenName
                tokenHolders {
                    loanId
                    address
                    balance
                    user {
                        id
                        fullName
                    }
                }
            }
        }
    }`;

export const ViewLoan = () => {
    const { isAdmin, isBorrower } = useRoles();
    const params = useParams();
    const { loanId } = params;
    const { data, loading, error } = useQuery<LoanDetails>(GET_LOAN, {
        variables: { 
            loanId: encodeId('Loan', loanId!)
        }
    });

    if (loading) {
        return <div>Spinner</div>;
    }

    if (error) {
        console.error(error);
        return <div>Sorry, an error occured while loading this loan.</div>;
    }

    const loan = data!.loan!.loan!;

    return (<div className="columns">
        <div className="column">
            <h2 className="title is-2">Loan # {loanId}</h2>
            <LoanInfo loan={loan} />
            <h4 className="title is-4">Payment Schedule</h4>
            <div className="columns">
                <div className="column">
                    <h5 className="title is-5">Payment History</h5>
                    <PaymentHistory principal={loan.principal} payments={loan.payments} />

                    {loan.balance > 0 && (isAdmin || isBorrower) ? (
                        <div className="mb-4">
                            <Link to={`/loans/${loanId}/payment`} className="button is-dark is-outlined">Make Payment</Link>
                        </div>
                    ) : null}
                    
                    <h5 className="title is-5">Future Payments</h5>
                    <FuturePayments balance={loan.balance} 
                        interestRate={loan.interestRate} 
                        dueDate={loan.dueDate} 
                        paymentAmount={loan.paymentAmount}
                        period={loan.period}
                        fee={loan.fee}
                        escrow={loan.monthlyInsurance + loan.monthlyTaxes} />
                </div>
            </div>
            <h4 className="title is-4">Payoff Amount</h4>
            <div className="columns">
                <div className="column is-3">
                    <DollarDisplayField label="Penalties Due" value={loan.duePenalties} />
                </div>
                <div className="column is-3">
                    <DollarDisplayField label="Interest Due" value={loan.dueInterest} />
                </div>
                <div className="column is-3">
                    <DollarDisplayField label="Principal Remaining" value={loan.balance} />
                </div>
                <div className="column is-3">
                    <DollarDisplayField label="Total" value={loan.balance + loan.dueInterest + loan.duePenalties} />
                </div>
            </div>
            <div className="columns">
                <div className="column">
                    <Link to={`/loans/${loanId}/payoff`} className="button is-dark is-outlined">View Payoff Statement</Link>
                </div>
            </div>
        </div>
    </div>);
}
