/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum Role {
  Admin = "Admin",
  Borrower = "Borrower",
  Lender = "Lender",
}

export interface CreateLoanInput {
  lenderId: string;
  borrowerId: string;
  street1: string;
  street2?: string | null;
  city: string;
  state: string;
  zip: string;
  principal: any;
  amortization: number;
  period: number;
  interestRate: any;
  latePaymentPeriod: number;
  latePaymentPenalty: any;
  fee: any;
  monthlyTaxes: any;
  monthlyInsurance: any;
  escrowBalance: any;
  allowPartialPayments: boolean;
  firstPaymentDate: any;
  payment: any;
}

export interface CreateUserInput {
  firstName: string;
  lastName: string;
  email: string;
  mobileNumber?: string | null;
  roles: Role[];
}

export interface EditUserInput {
  id: string;
  firstName: string;
  lastName: string;
  email?: string | null;
  mobileNumber?: string | null;
  roles: Role[];
}

export interface EscrowDistributionInput {
  loanId: string;
  amount: any;
}

export interface LoanPaymentInput {
  loanId: number;
  amount: any;
  isPrincipalPayment: boolean;
}

export interface LoanTokenTransferInput {
  loanId: string;
  sellerAddress: string;
  buyerAddress: string;
  amount: any;
  interestRate?: any | null;
}

export interface SignupWithKeyInput {
  key: string;
  firstName: string;
  lastName: string;
  email: string;
}

export interface UpdateEscrowInput {
  loanId: string;
  monthlyTaxes: any;
  monthlyInsurance: any;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
