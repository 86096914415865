import { Link } from 'react-router-dom';

interface PropertyInfo {
    street1: string,
    city: string,
    state: string,
};

interface LoanInfo {
    loanId: number,
    property: PropertyInfo
};

export const LoanTable = ({ loans }: { loans: LoanInfo[]}) => {
    return (
        <table className="table">
            <thead>
                <tr>
                    <th></th>
                    <th>ID</th>
                    <th>Property</th>
                </tr>
            </thead>
            <tbody>
                {loans.map((loan) => (
                    <tr key={loan.loanId}>
                        <td className="actions">
                            <Link to={`/loans/${loan.loanId}`}><i className="far fa-eye"></i></Link>
                        </td>
                        <td>{loan.loanId}</td>
                        <td>{loan.property.street1} {loan.property.city}, {loan.property.state}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}