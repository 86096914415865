import { useRoles } from "../../contexts";
import { LoanDetails_loan_loan_participants } from "../../routes/loans/__generated__/LoanDetails";
import { formatPercentage } from "../../util";
export const ParticipationInterestMessage = ({interestRate, participants}: {interestRate: number, participants: LoanDetails_loan_loan_participants[]}) => {
    const { isAdmin, isLender } = useRoles();
    if (!isAdmin && !isLender) {
        return <></>;
    }

    if (!participants || participants.length === 0) {
        return <></>;
    }

    const totalParticipation = participants.reduce((prev, current) => current.effectiveOwnership + prev, 0);
    const remainingInterest = interestRate - totalParticipation;

    return (
        <div className="is-italic mb-4">Interest for source token holders is {formatPercentage(remainingInterest)} due to {formatPercentage(totalParticipation)} participation in the original rate of {formatPercentage(interestRate)}.</div>
    )
}