import { useNavigate, useParams } from 'react-router-dom';
import { gql, useQuery, useMutation } from '@apollo/client';
import * as yup from 'yup';
import { decodeId, encodeId, toastError } from '../../util';
import { GetLoanTransferInfo } from './__generated__/GetLoanTransferInfo';
import { Checkbox, DisplayField, DollarDisplayField, DollarField, PercentDisplayField, PercentField, User, UserPicker } from '../../components';
import { Form, Formik } from 'formik';
import { toast } from 'react-toastify';
import { GET_LOAN } from './View';
import { TransferLoanTokens } from './__generated__/TransferLoanTokens';

interface Values {
    buyerAddress: string;
    amount: string;
    withParticipation: boolean;
    interestRate: string;
}

const initialValues: Values = {
    buyerAddress: '',
    amount: '',
    withParticipation: false,
    interestRate: '0',
}

const GET_TRANSFER_INFO = gql`
    query GetLoanTransferInfo($loanId: ID!) {
        loan(id: $loanId) {
            loan {
                id
                loanId
                interestRate
                property {
                    street1
                    street2
                    city
                    state
                    zip
                }
                principal
                participants {
                    address
                    interestRate
                    dollarAmount
                    effectiveOwnership
                    user {
                        id
                        fullName
                    }
                }
                tokenHolders {
                    loanId
                    address
                    balance
                    user {
                        id
                        fullName
                    }
                }
            }
        }
    }`;

const TRANSFER_LOAN_TOKENS = gql`
    mutation TransferLoanTokens($input: LoanTokenTransferInput!) {
        transferLoanTokens(input: $input) {
            ... on Loan {
                id
                loanId
            }
            ... on Error {
                title
                message
            }
        }
    }
`;

export const TransferTokens = () => {
    const params = useParams();
    const { loanId, sellerAddress } = params;
    const navigate = useNavigate();
    const { data, loading, error } = useQuery<GetLoanTransferInfo>(GET_TRANSFER_INFO, {
        variables: { 
            loanId: encodeId('Loan', loanId!)
        }
    });
    const [transferLoanTokens, transferStatus] = useMutation<TransferLoanTokens>(TRANSFER_LOAN_TOKENS);

    if (loading) {
        return <div>Spinner</div>;
    }

    if (error) {
        console.error(error);
        return <div>Sorry, an error occured while loading this loan.</div>;
    }

    const loan = data!.loan!.loan!;
    const seller = data!.loan!.loan!.tokenHolders?.find((tokenHolder) => tokenHolder.address === sellerAddress);
    if (!seller) {
        return <div>The seller could not be found.</div>;
    }

    const schema = yup.object().shape({
        buyerAddress: yup.string().required("Please select a buyer."),
        amount: yup.string()
            .test(
                "formatted-number",
                "Amount must be a number.",
                value => /^(\d{1,}|(\d{1,3}(,\d{3})+))(\.\d{2})?$/.test(value ?? "")
            )
            .test(
                "min-value",
                "Amount must be greater than zero.",
                value => Number(value?.replace(/,/g, '')) > 0
            )
            .test(
                "max-value",
                "Amount may not be greater than seller balance.",
                value => Number(value?.replace(/,/g, '')) <= seller.balance
            )
            .required("Amount is required"),
        withParticipation: yup.bool(),
        interestRate: yup.number()
            .min(0, "Interest rate must be at least 0%.")
            .max(loan.interestRate * 100, "Interest rate may not be more than loan rate.")
            .required("Interest rate is required.")
            .typeError("Interest rate must be a number."),
    })

    return (<div className="columns">
        <div className="column">
            <h2 className="title is-2">Sell Tokens of Loan # {loanId}</h2>
            <h4 className="title is-4">Loan</h4>
            <div className="columns">
                <div className="column is-3">
                    <DisplayField label="Property" value={
                        loan.property.street1 + "\n" +
                        (loan.property.street2 ? loan.property.street2 + "\n" : "") + 
                        loan.property.city + ", " + loan.property.state + " " + loan.property.zip
                    } />
                </div>
                <div className="column is-3">
                    <DollarDisplayField label="Principal" value={loan.principal} />
                </div>
                <div className="column is-3">
                    <PercentDisplayField label="Interest Rate" value={loan.interestRate} />
                </div>
            </div>
            <h4 className="title is-4">Seller</h4>
            <div className="columns">
                <div className="column is-3">
                    <div className="control">
                        <label className="label">Seller</label>
                        <User user={seller} />
                    </div>
                </div>
                <div className="column is-3">
                    <DollarDisplayField label="Balance" value={seller.balance} />
                </div>
            </div>
            <h4 className="title is-4">Transfer Tokens</h4>
            <Formik
                initialValues={initialValues}
                validationSchema={schema}
                onSubmit={(values) => {
                    const id = encodeId('Loan', loanId!);
                    const buyerAddress = decodeId(values.buyerAddress);
                    transferLoanTokens({
                        variables: {
                            input: {
                                loanId: id,
                                sellerAddress: seller.address,
                                buyerAddress: buyerAddress,
                                amount: Number(values.amount.replace(/,/g, '')),
                                interestRate: values.withParticipation ? Number(values.interestRate) / 100 : 0,
                            }
                        },
                        refetchQueries: [{ query: GET_LOAN, variables: { loanId: id }}],
                            onCompleted: (result) => {
                                if (result.transferLoanTokens.__typename === 'Loan') {
                                    toast.success(`Tokens transferred successfully.`);
                                    navigate(`/loans/${loanId}`);
                                } else if (result.transferLoanTokens.__typename === 'GeneralError') {
                                    toastError(result.transferLoanTokens);
                                }
                            }
                    })
                }}>
                {({ values }) => (
                    <Form>
                        <div className="columns">
                            <div className="column is-3">
                                <UserPicker label="Buyer" name="buyerAddress" required />
                            </div>
                            <div className="column is-3">
                                <DollarField label="Amount" name="amount" placeholder="1,000" required />
                            </div>
                        </div>
                        <div className="columns">
                            <div className="column is-3">
                                <Checkbox label="Sell with participation" name="withParticipation" />
                            </div>
                            <div className="column is-3">
                                {values.withParticipation ? (
                                    <PercentField label="Participation Rate" name="interestRate" 
                                        placeholder="2.0" helpText="Interest rate to be retained by the seller."
                                        required /> 
                                    ) : null}
                            </div> 
                        </div>
                        <button className="button is-primary" type="submit" disabled={transferStatus.loading}>Transfer Tokens</button>
                    </Form>
                )}
            </Formik>
        </div>
    </div>);
}