import { Link } from "react-router-dom";
import { getAddressLink } from "../../util";

interface UserOrAddress {
    user?: {
        id: string;
        fullName: string | null;
    } | null,
    address?: string;
}

export const User = ({ user }: { user: UserOrAddress }) => {
    if (user.user) {
        return <Link to={`/users/${user.user.id}`}>{user.user.fullName}</Link>;
    } else if (user.address) {
        return <a href={getAddressLink(user.address)} target="_blank" rel="noreferrer">{user.address}</a>;
    } else {
        return null;
    }
}