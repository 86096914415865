import { useContext, useState } from 'react';
import { Formik, Form } from 'formik'
import * as yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AuthContext } from '../../contexts';
import { Field } from '../../components';

const schema = yup.object().shape({
    email: yup.string().required("Please enter your email address."),
});

export const ForgotPassword = () => {
    const [showVerification, setShowVerification] = useState(false);
    const authContext = useContext(AuthContext);

    return (
    <div className="container">
        <div className="columns is-centered">
            <div className="column">
                <main>
                    <h1 className="title">Forgot Password</h1>
                    <Formik
                        initialValues={{
                            email: '',
                        }}
                        validationSchema={schema}
                        onSubmit={async (values) => {
                            try {
                                await authContext.forgotPassword(values.email);
                                setShowVerification(true);
                            } catch (err) {
                                console.error(err);
                            }
                        }}
                    >
                    {(props) => (
                        showVerification ? (
                            <ForgotPasswordVerification username={props.values.email} />
                        ) : (
                        <Form>
                            <div className="field">
                                <Field name="email" label="Email" required />
                            </div>
                            <div className="field is-grouped">
                                <div className="control">
                                    <button className="button is-link" type="submit">Submit</button>
                                </div>
                                <div className="control">
                                    <Link className="button is-link is-light" to="/signin">Cancel</Link>
                                </div>
                            </div>
                        </Form>
                        )
                    )}
                    </Formik>
                </main>
            </div>
        </div>
    </div>);
}

const verificationSchema = yup.object().shape({
    code: yup.string().required("Please enter your verification code."),
    password: yup.string().required("Please choose a password.")
        .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{12,}$/, 
            {
                message: 'Password must be at least 12 characters and contain capital letters, lowercase letters, and digits.',
                excludeEmptyString: true
            }),
    confirm: yup.string().oneOf([yup.ref('password'), null], "Passwords must match.")
});

export const ForgotPasswordVerification = ({ username }: { username: string } ) => {
    const authContext = useContext(AuthContext);
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();

    return (
        <>
            <p className="mb-4">Please check your email for a verification code.</p>
            <Formik
                initialValues={{
                    code: '',
                    password: '',
                    confirm: '',
                }}
                validationSchema={verificationSchema}
                onSubmit={async (values) => {
                    try {
                        await authContext.forgotPasswordSubmit(username, values.code, values.password);
                        toast.success('Password updated.');
                        await authContext.signIn(username, values.password);
                        navigate("/");
                    } catch (error) {
                        console.error('Error confirming validation code', error);
                        setError('Please check your validation code and try again.');
                    }
                }}
            >
                <Form>
                    <div className="field">
                        <Field name="code" label="Verification code" required />
                    </div>
                    <div className="field">
                        <Field name="password" label="New Password" type="password" required />
                    </div>
                    <div className="field">
                        <Field name="confirm" label="Confirm New Password" type="password" />
                    </div>
                    <div className="field is-grouped">
                        <div className="control">
                            <button className="button is-link" type="submit">Submit</button>
                        </div>
                        <div className="control">
                            <Link className="button is-link is-light" to="/signin">Cancel</Link>
                        </div>
                    </div>
                    {error ? (<p className="mt-4 is-danger">{error}</p>) : null}
                </Form>
            </Formik>
        </>
    );
}

export default ForgotPassword;