import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { gql, useQuery, useMutation } from '@apollo/client';
import { format, parseISO } from 'date-fns';
import * as yup from 'yup';
import { DisplayField, DollarDisplayField, DollarField } from '../../components/ui';
import { encodeId, toastError } from '../../util';
import { LoanPaymentInfo } from './__generated__/LoanPaymentInfo';
import { MakeLoanPayment } from './__generated__/MakeLoanPayment';
import { toast } from 'react-toastify';

const GET_LOAN_PAYMENT_INFO = gql`
    query LoanPaymentInfo($loanId: ID!) {
        loan(id: $loanId) {
            loan {
                id
                loanId
                interestRate
                latePaymentPeriod
                latePaymentPenalty
                fee
                firstPaymentDate
                paymentAmount
                balance
                dueDate
                duePrincipal
                dueInterest
                duePenalties
                dueFees
                dueEscrow
                allowPartialPayments
            }
        }
    }`;

export const MAKE_LOAN_PAYMENT = gql`
    mutation MakeLoanPayment($payment: LoanPaymentInput!) {
        makePayment(payment: $payment) {
            ... on LoanPaymentSuccess {
                loan {
                    id
                    loanId
                    balance
                    escrowBalance
                    payments {
                        id
                        date
                        penalties
                        principal
                        interest
                        fees
                        escrow
                    }
                    dueDate
                    duePrincipal
                    dueInterest
                    duePenalties
                    dueFees
                    dueEscrow
                }
            }
            ... on LoanPaymentError {
                title
                message
            }
        }
    }`;

const makeSchema = (minValue: number) => {
    const amountSchema = yup.string()
        .test(
            "formatted-number",
            "Amount must be a number.",
            value => /^(\d{1,}|(\d{1,3}(,\d{3})+))(\.\d{2})?$/.test(value ?? "")
        )
        .required("Amount is required.");

    const amount = minValue > 0 
        ? amountSchema.test(
            "min-value",
            "Partial payments are not allowed.",
            value => Number(value?.replace(/,/g, '')) >= minValue
        )
        : amountSchema.test(
            "min-value",
            "Amount must be greater than 0.",
            value => Number(value?.replace(/,/g, '')) >= 0
        );

    return yup.object().shape({
        amount
    });
}

export const RegularPaymentForm = ({ loanId }: { loanId: string }) => {
    const navigate = useNavigate();
    const { data, loading, error } = useQuery<LoanPaymentInfo>(GET_LOAN_PAYMENT_INFO, {
        variables: { 
            loanId: encodeId('Loan', loanId!)
        }
    });
    const [makePayment, mutationState] = useMutation<MakeLoanPayment>(MAKE_LOAN_PAYMENT);

    const schema = useMemo(() => {
        if (data) {
            const loan = data!.loan!.loan!;
            const minPayment = loan.allowPartialPayments 
                ? 0
                : loan.dueInterest + loan.duePrincipal + loan.duePenalties + loan.dueFees + loan.dueEscrow;
            return makeSchema(minPayment);
        }
        return null;
    }, [data])

    if (loading) {
        return <div>Spinner</div>;
    }

    if (error) {
        console.error(error);
        return <div>Sorry, an error occured while loading this loan.</div>;
    }

    const loan = data!.loan!.loan!;
    const totalDue = loan.dueInterest + loan.duePenalties + loan.duePrincipal + loan.dueFees + loan.dueEscrow;

    return (
        <>
            <div className="columns">
                <div className="column is-3">
                    <DisplayField label="Due Date" value={format(parseISO(loan.dueDate), 'P')} />
                </div>
            </div>
            <div className="columns">
                <div className="column is-2">
                    <DollarDisplayField label="Interest Due" value={loan.dueInterest} />
                </div>
                <div className="column is-2">
                    <DollarDisplayField label="Principal Due" value={loan.duePrincipal} />
                </div>
                <div className="column is-2">
                    <DollarDisplayField label="Penalties Due" value={loan.duePenalties} />
                </div>
                <div className="column is-2">
                    <DollarDisplayField label="Escrow Due" value={loan.dueEscrow} />
                </div>
                <div className="column is-2">
                    <DollarDisplayField label="Fees Due" value={loan.dueFees} />
                </div>
            </div>
            <div className="columns">
                <div className="column is-2">
                    <DollarDisplayField label="Total Due" value={totalDue} />
                </div>
            </div>
            <Formik
                initialValues={{ amount: totalDue ? totalDue.toFixed(2) : '' }}
                validationSchema={schema}
                onSubmit={(values) => {
                    makePayment({
                        variables: {
                            payment: {
                                loanId: Number(loanId),
                                amount: Number(values.amount.replace(/,/g, '')),
                                isPrincipalPayment: false
                            }
                        },
                        onCompleted: (result) => {
                            if (result.makePayment?.__typename === "LoanPaymentSuccess") {
                                toast.success(`Payment for $${values.amount} made.`)
                                navigate(`/loans/${loanId}`);
                            } else {
                                toastError(result.makePayment!);
                            }
                        }
                    });
                }}>
                <Form>
                    <div className="columns">
                        <div className="column is-3">
                            <DollarField label="Amount" placeholder="500.00" name="amount" required />
                        </div>
                    </div>
                    <button className="button is-primary" type="submit" disabled={loading || mutationState.loading}>Make Payment</button>
                </Form>
            </Formik>
        </>
    )
}